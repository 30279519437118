export function filterMap(elts, callback) {
  const accumulator = [];
  Array.from(elts).forEach((elt) => {
    const maybeTransformed = callback(elt);
    if (maybeTransformed !== null && maybeTransformed !== void 0) {
      accumulator.push(maybeTransformed);
    }
  });
  return accumulator;
}
