export const ok = (val) => ({
  isWitfulResult: true,
  ok: val
});
export const err = (error) => ({
  isWitfulResult: true,
  err: error
});
export const isOk = (result) => result.isWitfulResult && "ok" in result;
export const isErr = (result) => result.isWitfulResult && "err" in result;
