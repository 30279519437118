import isHotkey from "../../../../../pkg/is-hotkey.js";
import {listOnKeyDown} from "../modules/list/index.js";
import {promptForLinkUrl} from "../modules/link.js";
import {toggleMark} from "../utils/transforms/index.js";
import {removeFormatting} from "../utils/transforms/removeFormatting.js";
const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underlined",
  "mod+shift+x": "strikethrough",
  "mod+\\": null
};
export const onKeyDown = (editor) => (event) => {
  if (event.isPropagationStopped())
    return;
  listOnKeyDown(editor)(event);
  if (isHotkey("mod+k", event)) {
    promptForLinkUrl(editor);
  }
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.stopPropagation();
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      if (mark === null) {
        removeFormatting(editor);
      } else {
        toggleMark(editor, mark);
      }
      return;
    }
  }
  if (event.key == "Tab") {
    event.preventDefault();
  }
};
