var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorate = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import {FASTElement, customElement, attr} from "../../../../pkg/@microsoft/fast-element.js";
import React from "../../../../pkg/react.js";
import ReactDOM from "../../../../pkg/react-dom/client.js";
import {Editor, ActionItem, getEditorPlainText} from "../../editor/src/index.js";
import {contentToSlateDoc} from "./utils.js";
export {contentToSlateDoc} from "./utils.js";
export const init = (log, toEditorPlugins = () => ({})) => {
  let SlateEditor = class extends FASTElement {
    constructor() {
      super(...arguments);
      this.content = {data: "", type: "text"};
      this.entityId = "";
      this.eventData = "null";
      this.readOnly = false;
      this.debug = false;
      this.actionItemId = null;
      this.mentionNames = null;
      this.setSlateDoc = void 0;
      this.scrollToActionItem = void 0;
      this.setPalette = void 0;
      this.syncMentionNames = void 0;
      this.reactRoot = ReactDOM.createRoot(this);
    }
    connectedCallback() {
      super.connectedCallback();
      const slateDoc = contentToSlateDoc(this.content, log, {
        entityId: this.entityId,
        loc: "connectedCallback"
      });
      const setCallbacks = (callbacks) => {
        this.setSlateDoc = callbacks.setSlateDoc;
        this.scrollToActionItem = callbacks.scrollToActionItem;
        this.setPalette = callbacks.setPalette;
        this.syncMentionNames = callbacks.syncMentionNames;
      };
      const palette = this.palette ? JSON.parse(this.palette) : null;
      this.reactRoot.render(/* @__PURE__ */ React.createElement(Editor.Component, {
        slateDoc,
        debug: this.debug,
        entityId: this.entityId,
        readOnly: this.readOnly,
        palette,
        onChange: (editor) => this.onChange(editor),
        onInit: (callbacks) => setCallbacks(callbacks),
        plugins: toEditorPlugins(this.entityId, JSON.parse(this.eventData))
      }));
      this.dispatchEvent(new CustomEvent("slateInitialValue", {
        bubbles: true,
        detail: {slateDoc: JSON.stringify(slateDoc)}
      }));
      this.addEventListener("dragover", (event) => event.stopPropagation());
      this.addEventListener("drop", (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    }
    disconnectedCallback() {
      super.disconnectedCallback();
      this.reactRoot.unmount();
    }
    contentChanged(_, newContent) {
      const slateDoc = contentToSlateDoc(newContent, log, {
        entityId: this.entityId,
        loc: "contentChanged"
      });
      if (!slateDoc)
        return;
      this.setSlateDoc?.(slateDoc);
    }
    actionItemIdChanged(_, newActionItemId) {
      setTimeout(() => {
        this.scrollToActionItem?.(JSON.parse(newActionItemId));
      }, 0);
    }
    paletteChanged(_, newPalette) {
      this.setPalette?.(JSON.parse(newPalette));
    }
    mentionNamesChanged(_, newMentionNames) {
      if (newMentionNames) {
        setTimeout(() => {
          this.syncMentionNames?.(JSON.parse(newMentionNames));
        }, 0);
      }
    }
    onChange(editor) {
      const plaintext = getEditorPlainText(editor);
      const actionItems = ActionItem.extractActionItems(editor, this.entityId);
      this.$emit("slateDocChanged", {
        slateDoc: JSON.stringify(editor.children),
        plaintext,
        actionItems
      });
    }
  };
  __decorate([
    attr()
  ], SlateEditor.prototype, "content", 2);
  __decorate([
    attr()
  ], SlateEditor.prototype, "entityId", 2);
  __decorate([
    attr()
  ], SlateEditor.prototype, "eventData", 2);
  __decorate([
    attr()
  ], SlateEditor.prototype, "readOnly", 2);
  __decorate([
    attr()
  ], SlateEditor.prototype, "debug", 2);
  __decorate([
    attr()
  ], SlateEditor.prototype, "actionItemId", 2);
  __decorate([
    attr()
  ], SlateEditor.prototype, "palette", 2);
  __decorate([
    attr()
  ], SlateEditor.prototype, "mentionNames", 2);
  SlateEditor = __decorate([
    customElement({name: "slate-editor", shadowOptions: null})
  ], SlateEditor);
};
