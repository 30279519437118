import {Range, Transforms} from "../../../../../pkg/slate.js";
import {ReactEditor} from "../../../../../pkg/slate-react.js";
import isHotkey from "../../../../../pkg/is-hotkey.js";
const isEscapeKey = isHotkey("Escape");
export const onKeyUp = (editor) => (event) => {
  if (event.isPropagationStopped())
    return;
  if (isEscapeKey(event)) {
    const {selection} = editor;
    if (selection && Range.isExpanded(selection)) {
      Transforms.collapse(editor, {edge: "end"});
    } else {
      ReactEditor.blur(editor);
    }
    return;
  }
};
