import Rollbar from "./rollbar.js";
import env from "./env.js";
export const debugEnabled = false;
const isTestEnv = env.NODE_ENV === "test";
export const loadTime = Date.now();
const timeFromLoad = () => Date.now() - loadTime;
export const debug = (...msg) => {
  if (debugEnabled && env.NODE_ENV !== "production") {
    msg.unshift("debug:");
    msg.push("-");
    msg.push(timeFromLoad());
    console.log.apply(null, msg);
  }
};
export const info = function(title, data) {
  if (isTestEnv)
    return;
  console.info(title, data, timeFromLoad());
  Rollbar.info(title, data);
};
export const warning = function(title, data) {
  if (isTestEnv)
    return;
  console.warn(title, data, timeFromLoad());
  Rollbar.warning(title, data);
};
export const error = function(title, data) {
  if (isTestEnv)
    return;
  console.error(title, data, timeFromLoad());
  Rollbar.error(title, data);
};
export const critical = function(title, data) {
  if (isTestEnv)
    return;
  console.error(title, data, timeFromLoad());
  Rollbar.critical(title, data);
};
export const assert = function(b, title, data) {
  if (!b)
    error(title, data);
};
