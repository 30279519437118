import {Path, Transforms} from "../../../../../../../pkg/slate.js";
import {LIST_TYPES} from "../../../utils/constants.js";
import {
  findDescendant,
  getLastChildPath,
  matchElement
} from "../../../utils/queries/index.js";
import {moveChildren} from "../../../utils/transforms/index.js";
export const moveListItemsToList = (editor, {
  fromList,
  fromListItem,
  fromStartIndex,
  to: _to,
  toList,
  toListIndex = null,
  deleteFromList = true
}) => {
  let fromListPath;
  if (fromListItem) {
    const fromListItemSublist = findDescendant(editor, {
      at: fromListItem[1],
      match: matchElement(LIST_TYPES)
    });
    if (!fromListItemSublist)
      return false;
    fromListPath = fromListItemSublist?.[1];
  } else if (fromList) {
    fromListPath = fromList[1];
  } else {
    return false;
  }
  let to = null;
  if (_to)
    to = _to;
  if (toList) {
    if (toListIndex !== null)
      to = toList[1].concat([toListIndex]);
    else {
      const lastChildPath = getLastChildPath(toList);
      to = Path.next(lastChildPath);
    }
  }
  if (!to)
    return false;
  const numberChildrenMoved = moveChildren(editor, {
    at: fromListPath,
    to,
    fromStartIndex
  });
  if (deleteFromList) {
    Transforms.delete(editor, {at: fromListPath});
  }
  return numberChildrenMoved > 0;
};
