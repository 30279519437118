import {insertBreakList} from "./insertBreakList.js";
import {deleteBackwardList} from "./deleteBackwardList.js";
import {normalizeList} from "./normalizers/index.js";
import {deleteForwardList} from "./deleteForwardList.js";
import {insertFragmentList} from "./insertFragmentList.js";
export const withList = (editor) => {
  const {insertBreak, deleteBackward, deleteForward, deleteFragment} = editor;
  editor.insertBreak = () => {
    if (insertBreakList(editor))
      return;
    insertBreak();
  };
  editor.deleteBackward = (unit) => {
    if (deleteBackwardList(editor, unit))
      return;
    deleteBackward(unit);
  };
  editor.deleteForward = (unit) => {
    if (deleteForwardList(editor))
      return;
    deleteForward(unit);
  };
  editor.deleteFragment = (direction) => {
    deleteFragment(direction);
  };
  editor.insertFragment = insertFragmentList(editor);
  editor.normalizeNode = normalizeList(editor);
  return editor;
};
