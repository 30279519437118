import {Editor as SlateEditor, Transforms, Path} from "../../../../../../../pkg/slate.js";
import {getPreviousPath, isExpanded} from "../../../utils/queries/index.js";
import {moveListItemSublistItemsToListItemSublist} from "./moveListItemSublistItemsToListItemSublist.js";
import {moveListItemsToList} from "./moveListItemsToList.js";
import {hasListChild} from "../queries/index.js";
import {LI_TYPE, LIC_TYPE} from "../../../utils/constants.js";
export const removeListItem = (editor, {list, listItem, reverse = true}) => {
  const [liNode, liPath] = listItem;
  if (isExpanded(editor.selection) || !hasListChild(editor, liNode)) {
    return false;
  }
  const previousLiPath = getPreviousPath(liPath);
  if (previousLiPath) {
    const previousLi = SlateEditor.node(editor, previousLiPath);
    let tempLiPath = Path.next(liPath);
    Transforms.insertNodes(editor, {
      type: LI_TYPE,
      children: [{type: LIC_TYPE, children: [{text: ""}]}]
    }, {at: tempLiPath});
    const tempLi = SlateEditor.node(editor, tempLiPath);
    const tempLiPathRef = SlateEditor.pathRef(editor, tempLi[1]);
    moveListItemSublistItemsToListItemSublist(editor, {
      fromListItem: listItem,
      toListItem: tempLi
    });
    Transforms.delete(editor, {
      reverse
    });
    tempLiPath = tempLiPathRef.unref();
    moveListItemSublistItemsToListItemSublist(editor, {
      fromListItem: [tempLi[0], tempLiPath],
      toListItem: previousLi
    });
    Transforms.removeNodes(editor, {at: tempLiPath});
    return true;
  }
  moveListItemsToList(editor, {
    fromListItem: listItem,
    toList: list,
    toListIndex: 1
  });
  return false;
};
