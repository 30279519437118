import config from "./config.js";
import RollbarLib from "../../../../pkg/rollbar.js";
import env from "./env.js";
const _rollbarConfig = {
  enabled: env.NODE_ENV !== "development",
  endpoint: `https://${config.apiGateway}/rollbar`,
  accessToken: "281a53643dda46fea85683f6cd681945",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: env.NODE_ENV,
    client: {
      javascript: {
        code_version: env.COMMIT_HASH
      }
    }
  },
  scrubTelemetryInputs: true,
  async: false
};
const Rollbar = RollbarLib.init(_rollbarConfig);
export const setPerson = (userId, userEmail) => {
  Rollbar.configure({
    payload: {
      person: {
        id: userId,
        email: userEmail
      }
    }
  });
};
export default Rollbar;
