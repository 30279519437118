import {Editor, Path, Range} from "../../../../../../pkg/slate.js";
import {getBlockAbove} from "./index.js";
export const isRangeAcrossBlocks = (editor, at = editor.selection) => {
  if (!at)
    return false;
  const [start, end] = Range.edges(at);
  const startBlock = getBlockAbove(editor, start);
  const endBlock = Editor.above(editor, {at: end});
  return startBlock && endBlock && !Path.equals(startBlock[1], endBlock[1]);
};
