import firebase from "../../../../pkg/firebase/compat/app.js";
import "../../../../pkg/firebase/compat/auth.js";
import "../../../../pkg/firebase/compat/firestore.js";
import "../../../../pkg/firebase/compat/storage.js";
import config from "./config.js";
import {debug} from "./logging.js";
firebase.initializeApp(config.firebase);
const firestore = firebase.firestore();
const enableFirestorePersistence = () => firestore.enablePersistence({synchronizeTabs: true});
firebase.auth().useDeviceLanguage();
debug("Firebase/Firestore initialized");
const authProvider = () => {
  const authProvider2 = new firebase.auth.GoogleAuthProvider();
  authProvider2.setCustomParameters({prompt: "select_account"});
  return authProvider2;
};
export {firebase, firestore, authProvider, enableFirestorePersistence};
