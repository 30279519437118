import {useCallback, useMemo, useState} from "../../../../../../pkg/react.js";
import {Transforms, Range, Editor} from "../../../../../../pkg/slate.js";
import {getBlockAbove, matchElement} from "../../utils/queries/index.js";
import {
  displayTextForMention,
  getNextIndex,
  getPreviousIndex,
  isMentionType,
  matchesMentionSymbols
} from "./utils.js";
import {MENTION_TYPE} from "../../utils/constants.js";
import {extractMentions} from "./extractMentions.js";
import {generateActionItemId} from "../actionItem/util.js";
export const withMentions = (editor) => {
  const {isVoid, isInline} = editor;
  editor.isInline = (el) => {
    return el.type === MENTION_TYPE ? true : isInline(el);
  };
  editor.isVoid = (el) => {
    return el.type === MENTION_TYPE ? true : isVoid(el);
  };
  return editor;
};
export const initMentions = (callbacks) => {
  const [targetRange, setTargetRange] = useState(null);
  const [selectedOption, setSelectedOption] = useState(0);
  const [options, setOptions] = useState([]);
  useMemo(() => callbacks?.subscribeSearchResult(setOptions), []);
  const onAddMention = useCallback((editor, result) => {
    if (!isMentionType(result.data.mentionType)) {
      setTargetRange(null);
      throw Error(`onAddMention: unknown MentionType "${result.data.mentionType}"`);
    }
    if (targetRange !== null) {
      const mentionNode = {
        type: "mention",
        children: [{text: displayTextForMention(result.data)}],
        data: toMentionElementData(result.data)
      };
      Transforms.select(editor, targetRange);
      Transforms.insertNodes(editor, [mentionNode, {text: " "}]);
    }
  }, [targetRange]);
  const onKeyUp = useCallback((e) => {
    if (targetRange && e.key === "Escape") {
      e.preventDefault();
      e.stopPropagation();
      return setTargetRange(null);
    }
  }, [targetRange]);
  const onKeyDown = useCallback((editor) => (e) => {
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      setOptions([]);
      return;
    }
    if (options.length === 0)
      return;
    if (targetRange) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectedOption(getNextIndex(selectedOption, options.length - 1));
      }
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectedOption(getPreviousIndex(selectedOption, options.length - 1));
      }
      if (["Tab", "Enter"].includes(e.key) && selectedOption !== null) {
        const mentionOption = options[selectedOption];
        if (mentionOption) {
          e.preventDefault();
          e.stopPropagation();
          onAddMention(editor, mentionOption);
        }
      }
    }
    if (editor.selection && (e.key === "ArrowDown" || e.key === "ArrowUp")) {
      const [mention] = Editor.nodes(editor, {
        match: matchElement(MENTION_TYPE)
      });
      if (mention)
        e.preventDefault();
    }
  }, [targetRange, selectedOption, options, onAddMention]);
  const onChange = useCallback((editor) => {
    const {selection} = editor;
    if (selection && Range.isCollapsed(selection)) {
      const cursor = Range.start(selection);
      const {range, match: beforeMatch} = matchesMentionSymbols(editor, {
        at: cursor
      });
      if (beforeMatch) {
        setTargetRange(range);
        const [, mentionSymbol, word] = beforeMatch;
        const mentionsInLine = getCurrentLinesMentions(editor);
        callbacks?.sendSearchTerm?.({
          mentionSymbol,
          searchTerm: word,
          mentionsInLine
        });
        setSelectedOption(0);
        return;
      }
      setTargetRange(null);
    }
  }, [setTargetRange, setSelectedOption]);
  const getCurrentLinesMentions = (editor) => {
    const blockAbove = getBlockAbove(editor);
    if (!blockAbove)
      return [];
    return extractMentions(editor, blockAbove[1]);
  };
  const getMentionSelectProps = useCallback((palette) => ({
    at: targetRange,
    selectedOption,
    setSelectedOption,
    options,
    onClickOption: onAddMention,
    palette
  }), [targetRange, selectedOption, options, setSelectedOption, onAddMention]);
  return {
    onKeyDown,
    onKeyUp,
    onChange,
    getMentionSelectProps
  };
};
const toMentionElementData = (mentionData) => {
  switch (mentionData.mentionType) {
    case "event-mention": {
      return {
        ...mentionData,
        createdAt: Date.now(),
        completedAt: null,
        id: generateActionItemId()
      };
    }
    case "person-mention": {
      return mentionData;
    }
  }
};
