import {Editor} from "../../../../../../pkg/slate.js";
export const getBlockStringAfter = (editor) => {
  if (!editor.selection)
    return "";
  const lineEnd = Editor.after(editor, editor.selection, {
    unit: "line"
  });
  const afterRange = lineEnd && Editor.range(editor, lineEnd, editor.selection);
  return afterRange ? Editor.string(editor, afterRange) : "";
};
