import React from "../../../../../../pkg/react.js";
import ReactDOM from "../../../../../../pkg/react-dom.js";
import {cx, css} from "../../../../../../pkg/@emotion/css.js";
export const Button = React.forwardRef(({className, active, ...props}, ref) => /* @__PURE__ */ React.createElement("span", {
  ...props,
  ref,
  className: cx(className, active ? "button-active" : "button-inactive", css`
          cursor: pointer;
        `)
}));
export const MaterialIcon = React.forwardRef(({className, ...props}, ref) => /* @__PURE__ */ React.createElement("span", {
  ...props,
  ref,
  className: cx("material-icons", className, css`
          font-size: 18px;
          vertical-align: text-bottom;
        `)
}));
export const Icon = React.forwardRef(({iconName, className, ...props}, ref) => /* @__PURE__ */ React.createElement("span", {
  ...props,
  ref,
  style: {fontSize: "15px"},
  className: cx("fas", "fa-fw", `fa-${iconName}`, className)
}));
export const Menu = React.forwardRef(({className, ...props}, ref) => /* @__PURE__ */ React.createElement("div", {
  ...props,
  ref,
  className: cx(className, css`
          & > * {
            display: inline-block;
          }
          & > * + * {
            margin-left: 15px;
          }
        `)
}));
export const Portal = ({
  children
}) => {
  return typeof document === "object" ? ReactDOM.createPortal(children, document.body) : null;
};
