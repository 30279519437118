import {Path, Editor, Transforms} from "../../../../../../../pkg/slate.js";
import {wrapNodes} from "../../../utils/transforms/index.js";
import {matchElement} from "../../../utils/queries/index.js";
import {LIST_TYPES} from "../../../utils/constants.js";
export const moveListItemDown = (editor, {list, listItem}) => {
  const [listNode] = list;
  const [, listItemPath] = listItem;
  let previousListItemPath;
  try {
    previousListItemPath = Path.previous(listItemPath);
  } catch (e) {
    return;
  }
  const previousSiblingItem = Editor.node(editor, previousListItemPath);
  if (previousSiblingItem) {
    const [previousNode, previousPath] = previousSiblingItem;
    const sublist = previousNode.children.find(matchElement(LIST_TYPES));
    const newPath = previousPath.concat(sublist ? [1, sublist.children.length] : [1]);
    Editor.withoutNormalizing(editor, () => {
      if (!sublist) {
        wrapNodes(editor, {type: listNode.type, children: []}, {at: listItemPath});
      }
      Transforms.moveNodes(editor, {
        at: listItemPath,
        to: newPath
      });
    });
  }
};
