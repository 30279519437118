import {Editor} from "../../../../../../../pkg/slate.js";
import {matchActionItemElements} from "../matchActionItemElements.js";
export const getActionItemById = (editor, actionItemId) => {
  const [firstNode, secondNode] = Editor.nodes(editor, {
    at: [],
    match: (node) => matchActionItemElements(node) && node.data.id === actionItemId
  });
  if (secondNode) {
    throw new Error(`Action item with duplicate ID found: ${actionItemId}`);
  }
  return firstNode || null;
};
