import {Editor} from "../../../../../../pkg/slate.js";
export const getBlockStringBefore = (editor) => {
  if (!editor.selection)
    return "";
  const lineStart = Editor.before(editor, editor.selection, {
    unit: "line"
  });
  const beforeRange = lineStart && Editor.range(editor, lineStart, editor.selection);
  return beforeRange ? Editor.string(editor, beforeRange) : "";
};
