import {Editor, Path, Transforms} from "../../../../../../pkg/slate.js";
import {getListItemEntry, getListRoot, hasListChild} from "./queries/index.js";
import {
  getBlockAbove,
  getChildren,
  getNode,
  isSelectionAtBlockEnd
} from "../../utils/queries/index.js";
import {
  moveListItemsToList,
  moveListItemUp,
  removeFirstListItem,
  removeListItem
} from "./transforms/index.js";
import {LI_TYPE} from "../../utils/constants.js";
const pathToEntry = (editor, path) => Editor.node(editor, path);
const selectionIsNotInAListHandler = (editor) => {
  const pointAfterSelection = Editor.after(editor, editor.selection.focus.path);
  if (pointAfterSelection) {
    const nextSiblingListRes = getListItemEntry(editor, {
      at: pointAfterSelection
    });
    if (nextSiblingListRes) {
      const {listItem} = nextSiblingListRes;
      const parentBlockEntity = getBlockAbove(editor, editor.selection.anchor);
      if (!Editor.string(editor, parentBlockEntity[1])) {
        Transforms.removeNodes(editor);
        return true;
      }
      if (hasListChild(editor, listItem[0])) {
        const sublistRes = getListItemEntry(editor, {
          at: [...listItem[1], 1, 0, 0]
        });
        moveListItemUp(editor, sublistRes);
      }
    }
  }
  return false;
};
const selectionIsInAListHandler = (editor, res) => {
  const {listItem} = res;
  if (!hasListChild(editor, listItem[0])) {
    const liWithSiblings = Array.from(Editor.nodes(editor, {
      at: listItem[1],
      mode: "lowest",
      match: (node, path) => {
        if (path.length === 0) {
          return false;
        }
        const isNodeLi = node.type === LI_TYPE;
        const isSiblingOfNodeLi = getNode(editor, Path.next(path))?.type === LI_TYPE;
        return isNodeLi && isSiblingOfNodeLi;
      }
    }), (entry) => entry[1])[0];
    if (!liWithSiblings) {
      const pointAfterListItem = Editor.after(editor, listItem[1]);
      if (pointAfterListItem) {
        const nextSiblingListRes = getListItemEntry(editor, {
          at: pointAfterListItem
        });
        if (nextSiblingListRes) {
          const listRoot = getListRoot(editor, listItem[1]);
          moveListItemsToList(editor, {
            fromList: nextSiblingListRes.list,
            toList: listRoot,
            deleteFromList: true
          });
          return true;
        }
      }
      return false;
    }
    const siblingListItem = pathToEntry(editor, Path.next(liWithSiblings));
    const siblingList = Editor.parent(editor, siblingListItem[1]);
    if (removeListItem(editor, {
      list: siblingList,
      listItem: siblingListItem,
      reverse: false
    })) {
      return true;
    }
    return false;
  }
  const nestedList = pathToEntry(editor, Path.next([...listItem[1], 0]));
  const nestedListItem = getChildren(nestedList)[0];
  if (removeFirstListItem(editor, {
    list: nestedList,
    listItem: nestedListItem
  })) {
    return true;
  }
  return removeListItem(editor, {
    list: nestedList,
    listItem: nestedListItem
  });
};
export const deleteForwardList = (editor) => {
  let skipDefaultDelete = false;
  if (!editor?.selection) {
    return skipDefaultDelete;
  }
  if (!isSelectionAtBlockEnd(editor)) {
    return skipDefaultDelete;
  }
  Editor.withoutNormalizing(editor, () => {
    const res = getListItemEntry(editor, {});
    if (!res) {
      skipDefaultDelete = selectionIsNotInAListHandler(editor);
      return;
    }
    skipDefaultDelete = selectionIsInAListHandler(editor, res);
  });
  return skipDefaultDelete;
};
