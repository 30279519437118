export const dispatchUrlRequest = (event) => {
  const domNode = event.currentTarget;
  const url = domNode.href;
  event.preventDefault();
  if (!(url.startsWith(window.location.origin) || url.startsWith("/")) || event.metaKey) {
    window.open(url, "_blank");
    return;
  }
  if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.hasAttribute("download")) {
    event.preventDefault();
    domNode.dispatchEvent(new CustomEvent("witfulUrlRequest", {
      detail: {url},
      bubbles: true
    }));
  }
};
