import {Editor} from "../../../../../../../pkg/slate.js";
import {matchElement} from "../../../utils/queries/index.js";
import {OL_TYPE, UL_TYPE} from "../../../utils/constants.js";
export const getListRoot = (editor, at = editor.selection) => {
  if (!at)
    return;
  const parentList = Editor.above(editor, {
    at,
    match: matchElement([UL_TYPE, OL_TYPE])
  });
  if (parentList) {
    const [, parentListPath] = parentList;
    return getListRoot(editor, parentListPath) ?? parentList;
  }
  return;
};
