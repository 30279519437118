import {Editor, Text} from "../../../../../../pkg/slate.js";
import {getBlockAbove} from "./getBlockAbove.js";
import {getParent} from "./getParent.js";
import {getNextSiblingNodes} from "./getNextSiblingNodes.js";
export const isBlockTextEmptyAfterSelection = (editor) => {
  if (!editor.selection)
    return false;
  const blockAbove = getBlockAbove(editor);
  if (!blockAbove)
    return false;
  const cursor = editor.selection.focus;
  const selectionParentEntry = getParent(editor, editor.selection);
  if (!selectionParentEntry)
    return false;
  const [, selectionParentPath] = selectionParentEntry;
  if (!Editor.isEnd(editor, cursor, selectionParentPath))
    return false;
  const siblingNodes = getNextSiblingNodes(blockAbove, cursor.path);
  if (siblingNodes.length) {
    for (const siblingNode of siblingNodes) {
      if (Text.isText(siblingNode) && siblingNode.text) {
        return false;
      }
    }
  } else {
    return Editor.isEnd(editor, cursor, blockAbove[1]);
  }
  return true;
};
