import {getNodes} from "./getNodes.js";
export const findNode = (editor, options) => {
  try {
    const nodeEntries = getNodes(editor, {
      at: editor.selection || [],
      ...options
    });
    for (const [node, path] of nodeEntries) {
      return [node, path];
    }
    return void 0;
  } catch (error) {
    return void 0;
  }
};
