import {Editor} from "../../../../../../pkg/slate.js";
import {witfulUrls} from "../../../../utils/src/index.js";
import {getBlockAbove, matchElement} from "../../utils/queries/index.js";
import {MENTION_TYPE} from "../../utils/constants.js";
import {ReactEditor} from "../../../../../../pkg/slate-react.js";
export const MENTION_TYPES = ["person-mention", "event-mention"];
export const MENTION_SYMBOLS = ["@", "#"];
export const NEXT_MEETING_ID = "_witful-next-meeting";
export const NEXT_1_ON_1_ID = "_witful-next-1-on-1";
const nextMeetingIds = [
  NEXT_MEETING_ID,
  NEXT_1_ON_1_ID
];
const isNextMeetingId = (thing) => nextMeetingIds.includes(thing);
export const isMentionType = (value) => MENTION_TYPES.includes(value);
export const symbolForMentionType = (mentionType) => {
  switch (mentionType) {
    case "person-mention":
      return "@";
    case "event-mention":
      return "#";
  }
};
export const displayTextForMention = (mentionData) => {
  switch (mentionData.mentionType) {
    case "person-mention":
      return "@" + mentionData.name;
    case "event-mention": {
      if (mentionData.completedIn) {
        const {title, startTime} = mentionData.completedIn;
        return `#${title} - ${new Date(startTime).toLocaleDateString()}`;
      }
      return "#" + mentionData.title;
    }
  }
};
export const urlForMention = (editor, mention) => {
  switch (mention.data.mentionType) {
    case "person-mention":
      return witfulUrls.person(mention.data.personId);
    case "event-mention": {
      const {eventId, masterId, completedIn} = mention.data;
      if (completedIn)
        return eventUrl(editor, mention, completedIn.eventId, completedIn.masterId);
      return eventUrl(editor, mention, eventId, masterId);
    }
  }
};
const eventUrl = (editor, mention, eventId, masterId) => {
  if (eventId) {
    return witfulUrls.createEventNote(eventId, masterId, "mention-chip");
  }
  if (!masterId)
    return witfulUrls.home();
  if (isNextMeetingId(masterId)) {
    const mPersonId = grabFirstPersonMention(editor, mention)?.data.personId;
    switch (masterId) {
      case "_witful-next-meeting":
        return witfulUrls.nextMeeting(mPersonId);
      case "_witful-next-1-on-1":
        return witfulUrls.nextOneOnOne(mPersonId);
    }
  }
  return witfulUrls.nextInSeries(masterId);
};
const grabFirstPersonMention = (editor, meetingMention) => {
  const mentionPath = ReactEditor.findPath(editor, meetingMention);
  const currentBlock = getBlockAbove(editor, mentionPath);
  if (!currentBlock)
    return null;
  const [firstPersonMention] = Editor.nodes(editor, {
    at: currentBlock[1],
    match: (node) => matchElement(MENTION_TYPE)(node) && node.data.mentionType === "person-mention"
  });
  return firstPersonMention ? firstPersonMention[0] : null;
};
const symbols = MENTION_SYMBOLS.join("");
export const mentionRegex = new RegExp(`(?:^|\\s)([${symbols}])(\\S* ?[^\\s${symbols}]* ?[^\\s${symbols}]*)$`);
export const matchesMentionSymbols = (editor, {at}) => {
  const lineStart = Editor.before(editor, at, {unit: "line"});
  const beforeRange = lineStart && Editor.range(editor, lineStart, at);
  const beforeText = beforeRange ? Editor.string(editor, beforeRange) : "";
  const match = beforeText.match(mentionRegex);
  const mentionStart = match ? Editor.before(editor, at, {
    unit: "character",
    distance: match[1].length + match[2].length
  }) : null;
  const mentionRange = mentionStart && Editor.range(editor, mentionStart, at) || void 0;
  return {
    range: mentionRange,
    match
  };
};
export const getNextIndex = (i, max) => i == null || i >= max ? 0 : i + 1;
export const getPreviousIndex = (i, max) => i == null || i <= 0 ? max : i - 1;
