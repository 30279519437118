export const pause = (millis) => new Promise((resolve) => setTimeout(resolve, millis));
export const retry = async (fn, retries = 5, baseDelay = 100, maxDelay = 2e3) => retryHelp(fn, retries, baseDelay, maxDelay);
const retryHelp = async (fn, retries, baseDelay, maxDelay, counter = 0, err = null) => {
  if (counter >= retries) {
    return Promise.reject(err);
  }
  if (counter > 0) {
    await pause(Math.round(Math.random() * Math.min(maxDelay, baseDelay * 2 ** counter)));
  }
  return fn().catch((err2) => {
    return retryHelp(fn, retries, baseDelay, maxDelay, counter + 1, err2);
  });
};
