import {Editor, Element} from "../../../../../../pkg/slate.js";
import {filterMap} from "../../../../utils/src/index.js";
import {MENTION_TYPE} from "../../utils/constants.js";
import {matchElement} from "../../utils/queries/index.js";
export const extractMentions = (editor, blockPath) => {
  const mentionsNodeEntries = Array.from(Editor.nodes(editor, {
    match: matchElement(MENTION_TYPE),
    at: blockPath
  }));
  return filterMap(mentionsNodeEntries, ([el]) => {
    if (Element.isElement(el) && el.type === "mention") {
      switch (el.data.mentionType) {
        case "person-mention": {
          const personRef = {
            mentionType: "person-mention",
            personId: el.data.personId
          };
          return personRef;
        }
        case "event-mention": {
          const completedIn = el.data.completedIn === void 0 ? null : el.data.completedIn;
          const eventRef = {
            mentionType: "event-mention",
            eventId: el.data.eventId,
            masterId: el.data.masterId,
            completedIn,
            id: el.data.id,
            createdAt: el.data.createdAt,
            completedAt: el.data.completedAt
          };
          return eventRef;
        }
      }
    }
    return null;
  });
};
