import {
  Range as SlateRange,
  Path,
  Editor as SlateEditor,
  Transforms
} from "../../../../../../../pkg/slate.js";
import {
  getParent,
  isBlockTextEmptyAfterSelection,
  matchElement
} from "../../../utils/queries/index.js";
import {LI_TYPE, LIC_TYPE} from "../../../utils/constants.js";
export const insertListItem = (editor) => {
  if (!editor.selection)
    return;
  const licEntry = SlateEditor.above(editor, {
    match: matchElement(LIC_TYPE)
  });
  if (!licEntry)
    return;
  const [, paragraphPath] = licEntry;
  const listItemEntry = getParent(editor, paragraphPath);
  if (!listItemEntry)
    return;
  const [listItemNode, listItemPath] = listItemEntry;
  if (!matchElement(LI_TYPE)(listItemNode))
    return;
  if (!SlateRange.isCollapsed(editor.selection)) {
    Transforms.delete(editor);
  }
  const isStart = SlateEditor.isStart(editor, editor.selection.focus, paragraphPath);
  const isEnd = isBlockTextEmptyAfterSelection(editor);
  const nextParagraphPath = Path.next(paragraphPath);
  const nextListItemPath = Path.next(listItemPath);
  if (isStart) {
    Transforms.insertNodes(editor, {
      type: LI_TYPE,
      children: [{type: LIC_TYPE, children: [{text: ""}]}]
    }, {at: listItemPath});
    return true;
  }
  if (!isEnd) {
    SlateEditor.withoutNormalizing(editor, () => {
      Transforms.splitNodes(editor);
      Transforms.wrapNodes(editor, {type: LI_TYPE, children: []}, {at: nextParagraphPath});
      Transforms.moveNodes(editor, {
        at: nextParagraphPath,
        to: nextListItemPath
      });
      Transforms.select(editor, nextListItemPath);
      Transforms.collapse(editor, {
        edge: "start"
      });
    });
  } else {
    const marks = SlateEditor.marks(editor) || {};
    Transforms.insertNodes(editor, {
      type: LI_TYPE,
      children: [{type: LIC_TYPE, children: [{text: "", ...marks}]}]
    }, {at: nextListItemPath});
    Transforms.select(editor, nextListItemPath);
  }
  if (listItemNode.children.length > 1) {
    Transforms.moveNodes(editor, {
      at: nextParagraphPath,
      to: nextListItemPath.concat(1)
    });
  }
  return true;
};
