import {Path, Transforms, Editor} from "../../../../../../../pkg/slate.js";
import {getParent} from "../../../utils/queries/index.js";
import {isList} from "../queries/index.js";
export const normalizeNestedList = (editor, {nestedListItem}) => {
  const [, path] = nestedListItem;
  const parentNode = getParent(editor, path);
  const hasParentList = parentNode && isList(parentNode[0]);
  if (!hasParentList) {
    return false;
  }
  let previousListItemPath;
  try {
    previousListItemPath = Path.previous(path);
  } catch (e) {
    return false;
  }
  const previousSiblingItem = Editor.node(editor, previousListItemPath);
  if (previousSiblingItem) {
    const [, previousPath] = previousSiblingItem;
    const newPath = previousPath.concat([1]);
    Transforms.moveNodes(editor, {
      at: path,
      to: newPath
    });
    return true;
  }
  return false;
};
