import {Editor} from "../../../../../../pkg/slate.js";
import {isMarkActive} from "../queries/index.js";
export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
