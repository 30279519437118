import React from "../../../../../pkg/react.js";
export const Leaf = ({
  attributes,
  children,
  leaf
}) => {
  if (leaf.bold) {
    children = /* @__PURE__ */ React.createElement("strong", null, children);
  }
  if (leaf.italic) {
    children = /* @__PURE__ */ React.createElement("em", null, children);
  }
  if (leaf.underlined) {
    children = /* @__PURE__ */ React.createElement("u", null, children);
  }
  if (leaf.strikethrough) {
    children = /* @__PURE__ */ React.createElement("del", null, children);
  }
  if (leaf.code) {
    children = /* @__PURE__ */ React.createElement("code", null, children);
  }
  return /* @__PURE__ */ React.createElement("span", {
    ...attributes
  }, children);
};
