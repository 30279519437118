import {Path, Transforms} from "../../../../../../../pkg/slate.js";
import {
  findDescendant,
  getLastChildPath,
  getParent,
  matchElement
} from "../../../utils/queries/index.js";
import {moveChildren} from "../../../utils/transforms/index.js";
import {LIST_TYPES} from "../../../utils/constants.js";
export const moveListItemSublistItemsToListItemSublist = (editor, {
  fromListItem,
  toListItem,
  start
}) => {
  const [, fromListItemPath] = fromListItem;
  const [, toListItemPath] = toListItem;
  const fromListItemSublist = findDescendant(editor, {
    at: fromListItemPath,
    match: matchElement(LIST_TYPES)
  });
  if (!fromListItemSublist)
    return 0;
  const [, fromListItemSublistPath] = fromListItemSublist;
  const toListItemSublist = findDescendant(editor, {
    at: toListItemPath,
    match: matchElement(LIST_TYPES)
  });
  let to;
  if (!toListItemSublist) {
    const fromList = getParent(editor, fromListItemPath);
    if (!fromList)
      return 0;
    const [fromListNode] = fromList;
    const fromListType = fromListNode.type;
    const toListItemSublistPath = toListItemPath.concat([1]);
    Transforms.insertNodes(editor, {type: fromListType, children: []}, {at: toListItemSublistPath});
    to = toListItemSublistPath.concat([0]);
  } else if (start) {
    const [, toListItemSublistPath] = toListItemSublist;
    to = toListItemSublistPath.concat([0]);
  } else {
    to = Path.next(getLastChildPath(toListItemSublist));
  }
  const moved = moveChildren(editor, {
    at: fromListItemSublistPath,
    to
  });
  Transforms.delete(editor, {at: fromListItemSublistPath});
  return moved;
};
