export const ELEMENT_DEFAULT = "p";
export const P_TYPE = "p";
export const PRE_TYPE = "pre";
export const UL_TYPE = "ul";
export const OL_TYPE = "ol";
export const LI_TYPE = "li";
export const LIC_TYPE = "lic";
export const LIST_TYPES = [UL_TYPE, OL_TYPE];
export const H1_TYPE = "h1";
export const H2_TYPE = "h2";
export const H3_TYPE = "h3";
export const H4_TYPE = "h4";
export const H5_TYPE = "h5";
export const H6_TYPE = "h6";
export const HEADING_TYPES = [
  H1_TYPE,
  H2_TYPE,
  H3_TYPE,
  H4_TYPE,
  H5_TYPE,
  H6_TYPE
];
export const BLOCKQUOTE_TYPE = "blockquote";
export const IMAGE_TYPE = "img";
export const VIDEO_TYPE = "video";
export const LINK_TYPE = "a";
export const MENTION_TYPE = "mention";
export const ACTION_ITEM_CHIP_TYPE = "action-item-chip";
