import * as Log from "../../../../_snowpack/link/packages/frontend/common/logging.js";
export const subscribe = (elmApp) => {
  elmApp.ports.logError.subscribe((err) => {
    switch (err.severity) {
      case "critical":
        Log.critical(err.title, err.data);
        break;
      case "error":
        Log.error(err.title, err.data);
        break;
      case "warning":
        Log.warning(err.title, err.data);
        break;
      case "info":
        Log.info(err.title, err.data);
        break;
      case "debug":
        Log.debug(err.title, err.data);
        break;
      default:
        Log.error(`Unknown log level`, err);
        break;
    }
  });
};
