import {createEditor} from "../../../../../pkg/slate.js";
import {withHistory} from "../../../../../pkg/slate-history.js";
import {withReact} from "../../../../../pkg/slate-react.js";
import * as Scrubber from "./scrubber.js";
import {
  withList,
  withImages,
  withLinks,
  withMarkdown,
  withMentions,
  withVideos,
  withActionItems
} from "../modules/index.js";
const defaultModules = (plugins) => [
  withReact,
  withHistory,
  withList,
  withMarkdown,
  withLinks(plugins.appLinks),
  withImages(plugins.images),
  withVideos,
  withMentions,
  withActionItems(plugins.actionItems)
];
export const init = (plugins = {}, editor = createEditor()) => defaultModules(plugins).reduce((acc, module) => module(acc), editor);
Scrubber.init();
