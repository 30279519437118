import React, {useRef, useEffect, useCallback} from "../../../../../../pkg/react.js";
import {ReactEditor, useSlate} from "../../../../../../pkg/slate-react.js";
import {Editor, Range, Transforms} from "../../../../../../pkg/slate.js";
import {css} from "../../../../../../pkg/@emotion/css.js";
import {Button, Icon, MaterialIcon, Menu, Portal} from "./helpers.js";
import {toggleList} from "../../modules/list/transforms/index.js";
import {getBlockAbove, isMarkActive, matchElement} from "../../utils/queries/index.js";
import {toggleMark} from "../../utils/transforms/index.js";
import {isLinkActive, promptForLinkUrl} from "../../modules/link.js";
import {addOrRemoveActionItem} from "../../modules/actionItem/index.js";
import {getListItemEntry} from "../../modules/list/queries/index.js";
import {ACTION_ITEM_CHIP_TYPE} from "../../utils/constants.js";
import {removeFormatting} from "../../utils/transforms/removeFormatting.js";
const ListButton = ({
  format,
  icon
}) => {
  const editor = useSlate();
  const res = !!editor?.selection && getListItemEntry(editor);
  return /* @__PURE__ */ React.createElement(Button, {
    active: !!res && res.list[0].type === format,
    onMouseDown: (event) => {
      event.preventDefault();
      toggleList(editor, {type: format});
    }
  }, /* @__PURE__ */ React.createElement(MaterialIcon, null, icon));
};
const MarkButton = ({format, icon}) => {
  const editor = useSlate();
  return /* @__PURE__ */ React.createElement(Button, {
    active: isMarkActive(editor, format),
    onMouseDown: (event) => {
      event.preventDefault();
      toggleMark(editor, format);
    }
  }, /* @__PURE__ */ React.createElement(MaterialIcon, null, icon));
};
const ResetFormattingButton = () => {
  const editor = useSlate();
  return /* @__PURE__ */ React.createElement(Button, {
    active: false,
    onMouseDown: (event) => {
      event.preventDefault();
      removeFormatting(editor);
    },
    title: "Remove formatting"
  }, /* @__PURE__ */ React.createElement(MaterialIcon, null, "format_clear"));
};
const ActionItemButton = ({actionItemType}) => {
  const editor = useSlate();
  const iconName = actionItemType === "todo" ? "check-square" : "question-square";
  const containsActionItem = useCallback(() => {
    try {
      const [aiText] = Editor.nodes(editor, {
        match: matchElement(ACTION_ITEM_CHIP_TYPE)
      });
      return !!aiText;
    } catch (err) {
      return false;
    }
  }, [editor]);
  const addOrRemoveActionItemHelp = useCallback(() => {
    const {selection} = editor;
    if (selection && Range.isRange(selection)) {
      const blockAbove = getBlockAbove(editor, Editor.end(editor, selection));
      if (!blockAbove)
        return;
      addOrRemoveActionItem(editor, blockAbove, actionItemType);
      Transforms.setPoint(editor, selection.anchor);
      Transforms.move(editor, {distance: 2, unit: "offset"});
    }
  }, [editor]);
  return /* @__PURE__ */ React.createElement(Button, {
    active: containsActionItem(),
    onMouseDown: (event) => {
      event.preventDefault();
      addOrRemoveActionItemHelp();
    }
  }, /* @__PURE__ */ React.createElement(Icon, {
    iconName
  }));
};
const LinkButton = () => {
  const editor = useSlate();
  return /* @__PURE__ */ React.createElement(Button, {
    active: isLinkActive(editor),
    onMouseDown: (event) => {
      event.preventDefault();
      promptForLinkUrl(editor);
    }
  }, /* @__PURE__ */ React.createElement(MaterialIcon, null, "link"));
};
export const Toolbar = React.forwardRef((props, ref) => /* @__PURE__ */ React.createElement(Menu, {
  ref,
  className: props.menuClass
}, /* @__PURE__ */ React.createElement(MarkButton, {
  format: "bold",
  icon: "format_bold"
}), /* @__PURE__ */ React.createElement(MarkButton, {
  format: "italic",
  icon: "format_italic"
}), /* @__PURE__ */ React.createElement(MarkButton, {
  format: "underlined",
  icon: "format_underlined"
}), /* @__PURE__ */ React.createElement(MarkButton, {
  format: "strikethrough",
  icon: "format_strikethrough"
}), /* @__PURE__ */ React.createElement(ResetFormattingButton, null), /* @__PURE__ */ React.createElement(ListButton, {
  format: "ol",
  icon: "format_list_numbered"
}), /* @__PURE__ */ React.createElement(ListButton, {
  format: "ul",
  icon: "format_list_bulleted"
}), /* @__PURE__ */ React.createElement(LinkButton, null), /* @__PURE__ */ React.createElement(ActionItemButton, {
  actionItemType: "todo"
}), /* @__PURE__ */ React.createElement(ActionItemButton, {
  actionItemType: "talking-point"
})));
const toolbarBreakPoint = 430;
const fixedToolbarClass = css`
  display: none;
  padding-bottom: 20px;
  @media only screen and (max-width: ${toolbarBreakPoint}px) {
    display: block;
  }
  .button-active {
    color: #444;
  }
  .button-inactive {
    color: #777;
  }
`;
export const FixedToolbar = () => /* @__PURE__ */ React.createElement(Toolbar, {
  menuClass: fixedToolbarClass
});
const hoveringToolbarClass = css`
  padding: 10px 12px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  transition: opacity 0.25s;
  @media only screen and (max-width: ${toolbarBreakPoint}px) {
    display: none;
  }
  .button-active {
    color: #fff;
  }
  .button-inactive {
    color: #aaa;
  }
`;
export const HoveringToolbar = () => {
  const ref = useRef(null);
  const editor = useSlate();
  useEffect(() => {
    const el = ref.current;
    const {selection} = editor;
    if (!el) {
      return;
    }
    if (!selection || !ReactEditor.isFocused(editor) || Range.isCollapsed(selection) || Editor.string(editor, selection) === "") {
      el.removeAttribute("style");
      return;
    }
    const domSelection = window.getSelection();
    if (!domSelection) {
      return;
    }
    const selectionRect = domSelection.getRangeAt(0).getBoundingClientRect();
    const editorRect = ReactEditor.toDOMNode(editor, editor).getBoundingClientRect();
    const pxFromLeft = selectionRect.left + selectionRect.width / 2 + window.pageXOffset - el.offsetWidth / 2;
    const pxFromTop = Math.min(selectionRect.bottom, editorRect.bottom) + window.pageYOffset + el.offsetHeight / 2;
    el.style.opacity = "1";
    el.style.top = `${pxFromTop}px`;
    el.style.left = `${Math.max(pxFromLeft, 5)}px`;
  });
  return /* @__PURE__ */ React.createElement(Portal, null, /* @__PURE__ */ React.createElement(Toolbar, {
    ref,
    menuClass: hoveringToolbarClass
  }));
};
