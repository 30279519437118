import {Transforms, Editor} from "../../../../../pkg/slate.js";
import {HistoryEditor} from "../../../../../pkg/slate-history.js";
import {matchElement} from "../utils/queries/index.js";
import {isImageUrl} from "../../../utils/src/index.js";
import {IMAGE_TYPE} from "../utils/constants.js";
export const withImages = (imagesPlugin) => (editor) => {
  const {isVoid, insertData, isInline} = editor;
  editor.isInline = (el) => {
    return el.type === IMAGE_TYPE ? true : isInline(el);
  };
  editor.isVoid = (el) => {
    return el.type === IMAGE_TYPE ? true : isVoid(el);
  };
  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const file = data.files?.[0];
    const mime = file?.type?.split?.("/")?.[0];
    if (imagesPlugin && mime === "image") {
      if (!editor.selection)
        return;
      Transforms.insertNodes(editor, {
        type: IMAGE_TYPE,
        src: imagesPlugin.uploadPendingImageUrl,
        children: [{text: ""}]
      });
      const point = Editor.point(editor, editor.selection);
      const pointRef = Editor.pointRef(editor, point);
      Transforms.move(editor, {distance: 1, unit: "offset"});
      imagesPlugin.uploadImage(file).then((imageUrl) => {
        const currentPoint = pointRef.current;
        if (currentPoint) {
          HistoryEditor.withoutSaving(editor, () => {
            Transforms.setNodes(editor, {src: imageUrl}, {at: currentPoint, match: matchElement(IMAGE_TYPE)});
          });
          pointRef.unref();
        }
      }).catch((err) => {
        console.error(err);
        pointRef.unref();
      });
    } else if (isImageUrl(text)) {
      Transforms.insertNodes(editor, {
        type: IMAGE_TYPE,
        src: text,
        children: [{text: ""}]
      });
      Transforms.move(editor, {distance: 1, unit: "offset"});
    } else {
      insertData(data);
    }
  };
  return editor;
};
