import {jsx} from "../../../../../pkg/slate-hyperscript.js";
import {Transforms, Text, Editor} from "../../../../../pkg/slate.js";
import htmlParse from "../../../../../pkg/html-dom-parser.js";
import {init as initEditor} from "../editor/index.js";
import _ from "../../../../../pkg/lodash.js";
const removeUndefined = (obj) => _.pickBy(obj, (val) => val !== void 0);
const ELEMENT_TAGS = {
  a: (el) => ({type: "a", href: el.attribs.href}),
  blockquote: () => ({type: "blockquote"}),
  h1: () => ({type: "h1"}),
  h2: () => ({type: "h2"}),
  h3: () => ({type: "h3"}),
  h4: () => ({type: "h4"}),
  h5: () => ({type: "h5"}),
  h6: () => ({type: "h6"}),
  img: (el) => ({
    type: "img",
    src: el.attribs.src,
    alt: el.attribs.alt,
    width: el.attribs.width,
    height: el.attribs.height
  }),
  video: (el) => ({
    type: "video",
    src: el.attribs.src,
    mimeType: el.attribs.mimeType
  }),
  li: () => ({type: "li"}),
  ul: () => ({type: "ul"}),
  ol: () => ({type: "ol"}),
  p: () => ({type: "p"}),
  pre: () => ({type: "pre"})
};
const TEXT_TAGS = {
  code: "code",
  del: "strikethrough",
  em: "italic",
  i: "italic",
  s: "strikethrough",
  strong: "bold",
  u: "underlined"
};
const toResult = (result, errors = []) => ({
  result,
  errors
});
const deserialize = (elementOrText) => {
  const emptyText = jsx("text", {}, "");
  if (elementOrText.type === "text") {
    return toResult(jsx("text", {}, elementOrText.data));
  } else if (elementOrText.type === "comment") {
    return toResult([]);
  } else if (elementOrText.type !== "tag") {
    return toResult([], [`Unexpected DOM Node type ${elementOrText.type}`]);
  }
  const el = elementOrText;
  const nodeName = el.name;
  let parent = el;
  if (nodeName === "br") {
    if (el.children.length !== 0)
      console.error("BR should not have children");
    return toResult(jsx("text", {}, "\n"));
  }
  if (nodeName === "pre" && el.children[0]?.name === "code") {
    parent = el.children[0];
  }
  if (parent.children.length === 1 && parent.children[0].name === "br") {
    parent.children = [];
  }
  if (nodeName === "span" && el.children.length > 0) {
    const firstChild = el.children[0];
    const errors = [];
    if (firstChild.type !== "text") {
      errors.push(`span element's first child should be "text" instead of "${firstChild.type}"`);
      return toResult([], errors);
    }
    if (el.children.length > 1) {
      errors.push(`span element has more than one child. ${JSON.stringify(el.children.map((a) => a.type))}`);
    }
    return toResult(jsx("text", {}, firstChild.data), errors);
  }
  const childResults = parent.children.map(deserialize);
  const errorChildren = childResults.flatMap((c) => c.errors);
  const successChildren = childResults.map((c) => c.result);
  if (successChildren.length === 0) {
    successChildren.push(emptyText);
  }
  if (nodeName === "BODY") {
    return toResult(jsx("fragment", {}, successChildren), errorChildren);
  }
  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    const cleanAttrs = removeUndefined(attrs);
    return toResult(jsx("element", cleanAttrs, successChildren), errorChildren);
  }
  if (TEXT_TAGS[nodeName]) {
    const stylingAttr = TEXT_TAGS[nodeName];
    return toResult(successChildren.map((child) => jsx("text", {[stylingAttr]: true}, child)), errorChildren);
  }
  return toResult([], [`Unknown node name ${nodeName}`]);
};
export const deserializeFromHtml = (html) => {
  const defaultNode = {type: "p", children: [{text: ""}]};
  const normalize = (output2) => {
    const slateEditor = initEditor();
    const rootNodes = wrapRootInlineNodes(slateEditor, output2);
    Transforms.insertNodes(slateEditor, rootNodes);
    return slateEditor.children;
  };
  if (!html || html.trim().length === 0) {
    return {result: [defaultNode], errors: []};
  }
  const htmlElements = htmlParse(html);
  const nodes = htmlElements.map((el) => deserialize(el));
  const errors = nodes.flatMap((n) => n.errors);
  const output = nodes.flatMap((n) => n.result);
  if (output.length === 0) {
    output.push(defaultNode);
  }
  const normalizedOutput = normalize(output);
  return {result: normalizedOutput, errors};
};
const wrapRootInlineNodes = (editor, slateDoc) => {
  const reducer = (acc, node, index) => {
    const isNewline = Text.isText(node) && node.text === "\n";
    const nextNodeIsBlock = Editor.isBlock(editor, slateDoc[index + 1]);
    if (Editor.isInline(editor, node) || Text.isText(node)) {
      if (!isNewline)
        acc.inlineNodes.push(node);
      if (isNewline || nextNodeIsBlock) {
        acc.doc.push({type: "p", children: acc.inlineNodes});
        acc.inlineNodes = [];
      }
    } else {
      acc.doc.push(node);
    }
    return acc;
  };
  const finalAcc = slateDoc.reduce(reducer, {doc: [], inlineNodes: []});
  return finalAcc.inlineNodes.length === 0 ? finalAcc.doc : finalAcc.doc.concat([{type: "p", children: finalAcc.inlineNodes}]);
};
export default {deserializeFromHtml};
