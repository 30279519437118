import {Transforms, Editor} from "../../../../../../../pkg/slate.js";
import {ELEMENT_DEFAULT, LI_TYPE} from "../../../utils/constants.js";
import {matchElement} from "../../../utils/queries/index.js";
import {isList} from "../queries/index.js";
export const unwrapList = (editor, {at} = {}) => Editor.withoutNormalizing(editor, () => {
  do {
    Transforms.setNodes(editor, {
      type: ELEMENT_DEFAULT
    });
    Transforms.unwrapNodes(editor, {
      at,
      match: matchElement(LI_TYPE),
      split: true
    });
    Transforms.unwrapNodes(editor, {
      at,
      match: isList,
      split: true
    });
  } while (Editor.above(editor, {match: isList, at}));
});
