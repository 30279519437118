import {
  Editor,
  Range
} from "../../../../../../pkg/slate.js";
export const getNodes = (editor, options = {}) => {
  if (Range.isRange(options.at)) {
    options.at = Editor.unhangRange(editor, options.at, {
      voids: options.voids
    });
  }
  return Editor.nodes(editor, options);
};
