import { c as createCommonjsModule } from '../common/_commonjsHelpers-8c19dec8.js';
import { r as reactDom } from '../common/index-cde4ecde.js';
import '../common/index-9108b347.js';

var client = createCommonjsModule(function (module, exports) {


{
  exports.createRoot = reactDom.createRoot;
  exports.hydrateRoot = reactDom.hydrateRoot;
}
});

export default client;
