import {Editor, Transforms, Element} from "../../../../../../pkg/slate.js";
import {getBlockStringBefore} from "../../utils/queries/index.js";
import {ACTION_ITEM_CHIP_TYPE} from "../../utils/constants.js";
import {toEventMentionElement, toActionItemElement} from "./util.js";
import {dedupeActionItemIds} from "./dedupeActionItemIds.js";
const oneSecond = 1e3;
let _lastHitAiSymbol = 0;
export const withActionItems = (actionItemPlugin) => (editor) => {
  const {isInline, isVoid, insertText, apply} = editor;
  editor.isInline = (el) => {
    return el.type === ACTION_ITEM_CHIP_TYPE ? true : isInline(el);
  };
  editor.isVoid = (el) => {
    return el.type === ACTION_ITEM_CHIP_TYPE ? true : isVoid(el);
  };
  editor.apply = (operation) => {
    if (operation.type === "insert_node") {
      const {node} = operation;
      return apply({...operation, node: dedupeActionItemIds(editor)(node)});
    }
    return apply(operation);
  };
  editor.insertText = (text) => {
    if (!editor.selection)
      return insertText(text);
    while (text.startsWith("\uFEFF")) {
      text = text.substr(1);
    }
    if (text === "!" || text === "?") {
      const prevLastHitAiSymbol = _lastHitAiSymbol;
      _lastHitAiSymbol = Date.now();
      if (_lastHitAiSymbol - prevLastHitAiSymbol > oneSecond) {
        return insertText(text);
      }
      const charBeforeCursor = getBlockStringBefore(editor).slice(-1);
      if (charBeforeCursor === "") {
        const previousItem = Editor.previous(editor);
        if (!previousItem)
          return insertText(text);
        const [prevNode, prevPath] = previousItem;
        const previousItemOnSameLine = editor.selection.anchor.path[0] === prevPath[0];
        if (previousItemOnSameLine && Element.isElement(prevNode) && prevNode.type === ACTION_ITEM_CHIP_TYPE && prevNode.data.aiType === "todo" && prevNode.data.priority === "priority-normal" && text === "!" && Date.now() - prevNode.data.createdAt < oneSecond) {
          Transforms.setNodes(editor, {data: {...prevNode.data, priority: "priority-high"}}, {at: prevPath});
          return;
        }
        return insertText(text);
      }
      if (charBeforeCursor === "!" || charBeforeCursor === "?") {
        const aiSymbol = text + charBeforeCursor;
        let aiElement;
        if (aiSymbol === "!!") {
          aiElement = toActionItemElement("todo");
        } else if (aiSymbol === "??") {
          if (actionItemPlugin?.eventData) {
            aiElement = toEventMentionElement({
              eventId: actionItemPlugin.eventData.eventId,
              masterId: actionItemPlugin.eventData.masterId,
              title: actionItemPlugin.eventData.title,
              currentTopic: true
            });
          } else {
            aiElement = toActionItemElement("talking-point");
          }
        } else {
          console.error("Error with action item shortcut key logic", {
            aiSymbol
          });
        }
        if (aiElement) {
          Editor.withoutNormalizing(editor, () => {
            editor.deleteBackward("character");
            Transforms.insertText(editor, " ");
            Transforms.insertNodes(editor, [
              aiElement,
              {text: ""}
            ]);
          });
          return;
        }
      }
    }
    insertText(text);
  };
  return editor;
};
