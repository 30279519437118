import {Element} from "../../../../pkg/slate.js";
import {Deserialize} from "../../editor/src/index.js";
export const contentToSlateDoc = (content, log, loggingContext = {}) => {
  const logError = (errorContext = {}) => log.error("SlateEditor.contentToSlateDoc failed", {
    ...loggingContext,
    contentType: content.type,
    ...errorContext
  });
  switch (content.type) {
    case "slateDoc":
      try {
        const slateDoc = JSON.parse(content.data);
        const cleanSlateDoc = slateDoc.filter(Element.isElement);
        const nonElementsInRootNode = slateDoc.length - cleanSlateDoc.length;
        if (nonElementsInRootNode > 0 || cleanSlateDoc.length === 0) {
          logError({
            err: "Not a valid Slate element list",
            slateDocLength: slateDoc.length,
            cleanedSlatedDocLength: cleanSlateDoc.length,
            nonElementsInRootNode
          });
          return cleanSlateDoc.length > 0 ? cleanSlateDoc : toSimpleSlateDoc();
        }
        return slateDoc;
      } catch (err) {
        logError({err});
        return toSimpleSlateDoc();
      }
    case "html":
      try {
        const slateDocResult = Deserialize.deserializeFromHtml(content.data);
        const parsingErrors = slateDocResult.errors;
        if (parsingErrors.length > 0) {
          log.warning("SlateEditor.contentToSlateDoc html parsing errors", {
            parsingErrors
          });
        }
        return slateDocResult.result;
      } catch (err) {
        logError({err});
        return toSimpleSlateDoc();
      }
    case "text":
      return toSimpleSlateDoc(content.data);
  }
};
export const toSimpleSlateDoc = (str = "") => [
  {type: "p", children: [{text: str}]}
];
