import {Editor, Node, Element} from "../../../../../../../pkg/slate.js";
import {getBlockAbove} from "../../../utils/queries/index.js";
import {filterMap} from "../../../../../utils/src/index.js";
import {matchActionItemElements} from "../matchActionItemElements.js";
export const extractActionItems = (editor, noteId) => {
  const actionItems = Array.from(Editor.nodes(editor, {at: [], match: matchActionItemElements}));
  const actionItemBlockPaths = new Set();
  return filterMap(actionItems, ([, path]) => {
    const blockEntry = getBlockAbove(editor, path);
    if (!blockEntry)
      return null;
    const [blockNode, blockPath] = blockEntry;
    const actionItemParentPathId = blockPath.toString();
    if (actionItemBlockPaths.has(actionItemParentPathId))
      return null;
    let aiData;
    const text = filterMap(blockNode.children, (node) => {
      if (Element.isElement(node)) {
        if (node.type === "mention") {
          if (node.data.mentionType === "event-mention") {
            const {id, createdAt, completedAt} = node.data;
            aiData ||= {id, createdAt, completedAt};
          }
          return node.data;
        }
        if (node.type === "action-item-chip") {
          const {id, createdAt, completedAt} = node.data;
          aiData ||= {id, createdAt, completedAt};
          return node.data;
        }
      }
      const plaintext = Node.string(node);
      if (plaintext === "")
        return null;
      return {plaintext};
    });
    actionItemBlockPaths.add(actionItemParentPathId);
    if (aiData === void 0) {
      console.warn("Missing Witful.BaseActionItem attributes", aiData);
      return null;
    }
    return {noteId, text, ...aiData};
  });
};
