export const firebase = {
  apiKey: "AIzaSyAS2BvIzA3J8ud3NaQ_PQTSHLE6p0IDoaE",
  authDomain: "witful-production.firebaseapp.com",
  databaseURL: "https://witful-production.firebaseio.com",
  projectId: "witful-production",
  storageBucket: "witful-production.appspot.com",
  messagingSenderId: "718599249298",
  appId: "1:718599249298:web:64b197523f89b45dad754b",
  clientId: "718599249298-7lpqeir37maedju3mn9m1u7jii156joe.apps.googleusercontent.com",
  scopes: [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/calendar.events.readonly"
  ]
};
export const apiGateway = "log-proxy-gw-964bftea.uc.gateway.dev";
export const amplitudeKey = window.location.hostname == "next.witful.com" ? "b41feacaf65270840dcdde92d2f4e9c4" : "c2af7d5f681778ff48dd04409093054e";
export const mixpanelKey = window.location.hostname == "next.witful.com" ? "f35cf0607ade84c5d6ae6ea993f22f02" : "61c1d35ccc08b85c052fac8ab7afc85b";
