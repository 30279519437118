import {Editor, Node, Path, Range, Span} from "../../../../../../pkg/slate.js";
export const findDescendant = (editor, options) => {
  try {
    const {
      match,
      at = editor.selection,
      reverse = false,
      voids = false
    } = options;
    if (!at)
      return;
    let from, to;
    if (Span.isSpan(at)) {
      [from, to] = at;
    } else if (Range.isRange(at)) {
      const first = Editor.path(editor, at, {edge: "start"});
      const last = Editor.path(editor, at, {edge: "end"});
      from = reverse ? last : first;
      to = reverse ? first : last;
    }
    let root = [editor, []];
    if (Path.isPath(at)) {
      root = Editor.node(editor, at);
    }
    const nodeEntries = Node.descendants(root[0], {
      reverse,
      from,
      to,
      pass: ([n]) => voids ? false : Editor.isVoid(editor, n)
    });
    for (const [node, path] of nodeEntries) {
      if (match(node)) {
        return [node, at.concat(path)];
      }
    }
  } catch (error) {
    return void 0;
  }
  return void 0;
};
