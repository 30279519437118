import {Path} from "../../../../../../pkg/slate.js";
export const getLastChild = (nodeEntry) => {
  const [node, path] = nodeEntry;
  if (!node.children.length)
    return null;
  return [
    node.children[node.children.length - 1],
    path.concat([node.children.length - 1])
  ];
};
export const getLastChildPath = (nodeEntry) => {
  const lastChild = getLastChild(nodeEntry);
  if (!lastChild)
    return nodeEntry[1].concat([-1]);
  return lastChild[1];
};
export const isLastChild = (parentEntry, childPath) => {
  const lastChildPath = getLastChildPath(parentEntry);
  return Path.equals(lastChildPath, childPath);
};
