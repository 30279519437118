import {isFirstChild} from "../../../utils/queries/index.js";
import {moveListItemUp} from "./moveListItemUp.js";
import {isListNested} from "../queries/index.js";
export const removeFirstListItem = (editor, {list, listItem}) => {
  const [, listPath] = list;
  const [, listItemPath] = listItem;
  if (!isListNested(editor, listPath) && !isFirstChild(listItemPath)) {
    moveListItemUp(editor, {list, listItem});
    return true;
  }
  return false;
};
