import {Transforms, Range} from "../../../../../../pkg/slate.js";
import {ELEMENT_DEFAULT} from "../constants.js";
import {matchElement, someNode} from "../queries/index.js";
export const resetNode = (editor, {type, predicate, onReset}) => {
  if (editor.selection && Range.isCollapsed(editor.selection)) {
    if (predicate(editor) && someNode(editor, {match: matchElement(type)})) {
      Transforms.setNodes(editor, {type: ELEMENT_DEFAULT});
      onReset?.(editor);
      return true;
    }
  }
  return false;
};
