import {
  Editor,
  Path,
  Transforms
} from "../../../../../../../pkg/slate.js";
import {getChildren, getParent} from "../../../utils/queries/index.js";
import {insertEmptyElement} from "../../../utils/transforms/index.js";
import {LIC_TYPE, LIST_TYPES} from "../../../utils/constants.js";
import {isList} from "../queries/index.js";
import {moveListItemUp} from "../transforms/index.js";
const getDeepInlineChildren = (editor, {children}) => {
  const inlineChildren = [];
  for (const child of children) {
    if (Editor.isBlock(editor, child[0])) {
      inlineChildren.push(...getDeepInlineChildren(editor, {
        children: getChildren(child)
      }));
    } else {
      inlineChildren.push(child);
    }
  }
  return inlineChildren;
};
export const normalizeListItem = (editor, {listItem}) => {
  let changed = false;
  const allValidLiChildrenTypes = [
    LIC_TYPE,
    ...LIST_TYPES
  ];
  const [, liPath] = listItem;
  const liChildren = getChildren(listItem);
  const invalidLiChildrenPathRefs = liChildren.filter(([child]) => !allValidLiChildrenTypes.includes(child.type)).map(([, childPath]) => Editor.pathRef(editor, childPath));
  const firstLiChild = liChildren[0];
  if (!firstLiChild || !Editor.isBlock(editor, firstLiChild?.[0])) {
    insertEmptyElement(editor, LIC_TYPE, {
      at: liPath.concat([0])
    });
    return true;
  }
  const [firstLiChildNode, firstLiChildPath] = firstLiChild;
  if (Editor.isBlock(editor, firstLiChildNode) && firstLiChildNode.type !== LIC_TYPE) {
    if (isList(firstLiChildNode)) {
      const parent = getParent(editor, listItem[1]);
      const sublist = firstLiChild;
      const children = getChildren(firstLiChild).reverse();
      children.forEach((c) => {
        moveListItemUp(editor, {list: sublist, listItem: c});
      });
      Transforms.removeNodes(editor, {at: [...parent[1], 0]});
      return true;
    }
    Transforms.setNodes(editor, {type: LIC_TYPE}, {at: firstLiChildPath});
    changed = true;
  }
  const licChildren = getChildren(firstLiChild);
  if (licChildren.length) {
    const blockPathRefs = [];
    const inlineChildren = [];
    for (const licChild of licChildren) {
      if (!Editor.isBlock(editor, licChild[0])) {
        break;
      }
      blockPathRefs.push(Editor.pathRef(editor, licChild[1]));
      inlineChildren.push(...getDeepInlineChildren(editor, {
        children: getChildren(licChild)
      }));
    }
    const to = Path.next(licChildren[licChildren.length - 1]?.[1]);
    inlineChildren.reverse().forEach(([, path]) => {
      Transforms.moveNodes(editor, {
        at: path,
        to
      });
    });
    blockPathRefs.forEach((pathRef) => {
      const path = pathRef.unref();
      path && Transforms.removeNodes(editor, {
        at: path
      });
    });
    if (blockPathRefs.length) {
      changed = true;
    }
  }
  if (changed)
    return true;
  invalidLiChildrenPathRefs.reverse().forEach((ref) => {
    const path = ref.unref();
    path && Transforms.moveNodes(editor, {
      at: path,
      to: firstLiChildPath.concat([0])
    });
  });
  return !!invalidLiChildrenPathRefs.length;
};
