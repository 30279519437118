import {isBlockAboveEmpty} from "../../utils/queries/index.js";
import {moveListItemUp, insertListItem} from "./transforms/index.js";
import {getListItemEntry} from "./queries/index.js";
export const insertBreakList = (editor) => {
  if (!editor.selection)
    return;
  const res = getListItemEntry(editor, {});
  let moved;
  if (res) {
    const {list, listItem} = res;
    if (isBlockAboveEmpty(editor)) {
      moved = moveListItemUp(editor, {
        list,
        listItem
      });
      if (moved)
        return true;
    }
  }
  if (!moved) {
    const inserted = insertListItem(editor);
    if (inserted)
      return true;
  }
  return;
};
