import {Node, Path, Transforms, Range, Editor} from "../../../../../../pkg/slate.js";
import {isList} from "./queries/index.js";
import {LI_TYPE, LIST_TYPES} from "../../utils/constants.js";
import {findNode, matchElement} from "../../utils/queries/index.js";
export const insertFragmentList = (editor) => {
  const {insertFragment} = editor;
  const getFirstAncestorOfType = (root, entry, nodeType) => {
    let ancestor = Path.parent(entry[1]);
    while (Node.get(root, ancestor).type !== nodeType) {
      ancestor = Path.parent(ancestor);
    }
    return [Node.get(root, ancestor), ancestor];
  };
  const trimList = (listRoot) => {
    if (!isList(listRoot)) {
      return [listRoot];
    }
    const textEntries = Array.from(Node.texts(listRoot));
    const commonAncestorEntry = textEntries.reduce((commonAncestor, textEntry) => Path.isAncestor(commonAncestor[1], textEntry[1]) ? commonAncestor : Node.common(listRoot, textEntry[1], commonAncestor[1]), getFirstAncestorOfType(listRoot, textEntries[0], LI_TYPE));
    return isList(commonAncestorEntry[0]) ? commonAncestorEntry[0].children : [commonAncestorEntry[0]];
  };
  return (fragment) => {
    if (editor.selection && Range.isExpanded(editor.selection)) {
      editor.deleteFragment("backward");
    }
    const liEntry = findNode(editor, {
      match: matchElement(LI_TYPE),
      mode: "lowest"
    });
    if (liEntry) {
      const [liNode, liPath] = liEntry;
      if (fragment.length === 1 && !matchElement(LIST_TYPES)(fragment[0])) {
        return insertFragment(fragment);
      }
      const hasSublist = liNode.children.some(matchElement(LIST_TYPES));
      if (!hasSublist && Node.string(liNode) === "") {
        Editor.withoutNormalizing(editor, () => {
          Transforms.removeNodes(editor, {at: liPath});
          Transforms.insertNodes(editor, fragment.flatMap((node) => trimList(node)), {at: liPath, select: true});
        });
        return;
      }
      return Transforms.insertNodes(editor, fragment.flatMap((node) => trimList(node)), {at: Path.next(liPath), select: true});
    }
    const filtered = isList(fragment[0]) ? [{text: ""}, ...fragment] : fragment;
    return insertFragment(filtered);
  };
};
