import {Editor, Transforms, Range, Element, Node} from "../../../../../../pkg/slate.js";
import {toggleList} from "../list/transforms/index.js";
import {
  getBlockAbove,
  getLineStringAfter,
  getLineStringBefore,
  matchElement
} from "../../utils/queries/index.js";
import {
  BLOCKQUOTE_TYPE,
  ELEMENT_DEFAULT,
  HEADING_TYPES,
  PRE_TYPE
} from "../../utils/constants.js";
const SHORTCUTS = {
  "*": "ul",
  "-": "ul",
  "+": "ul",
  "1.": "ol",
  ">": "blockquote",
  "#": "h1",
  "##": "h2",
  "###": "h3",
  "####": "h4",
  "#####": "h5",
  "######": "h6"
};
const nonSoftBreakableTypes = HEADING_TYPES;
const softBreakableTypes = [BLOCKQUOTE_TYPE, PRE_TYPE];
export const withMarkdown = (editor) => {
  const {insertText, insertBreak, deleteBackward} = editor;
  editor.insertText = (text) => {
    const {selection} = editor;
    if (text === " " && selection && Range.isCollapsed(selection)) {
      const {anchor} = selection;
      const block = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n)
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = {anchor, focus: start};
      const beforeText = Editor.string(editor, range);
      const type = SHORTCUTS[beforeText];
      const [parentBlock] = Editor.parent(editor, selection);
      const withinList = Element.isElement(parentBlock) && parentBlock.type === "lic";
      if (type && !withinList) {
        Transforms.select(editor, range);
        Transforms.delete(editor);
        if (type === "ul" || type === "ol") {
          toggleList(editor, {type});
        } else {
          Transforms.setNodes(editor, {type}, {match: (n) => Editor.isBlock(editor, n)});
        }
        return;
      }
    }
    insertText(text);
  };
  editor.insertBreak = () => {
    const blockAbove = getBlockAbove(editor);
    if (blockAbove) {
      const [blockAboveNode, blockAbovePath] = blockAbove;
      const relevantTypes = nonSoftBreakableTypes.concat(softBreakableTypes);
      if (matchElement(relevantTypes)(blockAboveNode)) {
        if (!editor.selection)
          return insertBreak();
        const textBeforeCursor = getLineStringBefore(editor);
        const textAfterCursor = getLineStringAfter(editor);
        const cursorStart = Editor.start(editor, editor.selection);
        if (cursorStart.offset == 0 && textAfterCursor !== "") {
          insertBreak();
          Transforms.setNodes(editor, {type: ELEMENT_DEFAULT}, {at: blockAbovePath});
          return;
        }
        if (textBeforeCursor === "") {
          editor.deleteBackward("character");
          insertBreak();
          if (textAfterCursor === "") {
            Transforms.setNodes(editor, {type: ELEMENT_DEFAULT});
          }
          return;
        }
        if (textBeforeCursor !== "" && matchElement(softBreakableTypes)(blockAboveNode)) {
          Transforms.insertText(editor, "\n");
          return;
        }
        if (textAfterCursor === "" && matchElement(nonSoftBreakableTypes)(blockAboveNode)) {
          insertBreak();
          Transforms.setNodes(editor, {type: ELEMENT_DEFAULT});
          return;
        }
      }
    }
    return insertBreak();
  };
  editor.deleteBackward = (unit) => {
    const blockAbove = getBlockAbove(editor);
    if (blockAbove) {
      const [blockAboveNode] = blockAbove;
      const relevantTypes = nonSoftBreakableTypes.concat(softBreakableTypes);
      if (matchElement(relevantTypes)(blockAboveNode) && Node.string(blockAboveNode) === "") {
        Transforms.setNodes(editor, {type: ELEMENT_DEFAULT});
        return;
      }
    }
    return deleteBackward(unit);
  };
  return editor;
};
