import React from "../../../../../pkg/react.js";
import {useSlate} from "../../../../../pkg/slate-react.js";
export const Debugger = () => {
  const editor = useSlate();
  const state = JSON.stringify(editor.children, null, 2);
  const selectionAnchor = JSON.stringify(editor.selection?.anchor);
  const selectionFocus = JSON.stringify(editor.selection?.focus);
  return /* @__PURE__ */ React.createElement("div", {
    id: "slate-debugger"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", null, "Anchor: ", selectionAnchor), /* @__PURE__ */ React.createElement("div", null, "Focus: ", selectionFocus)), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", null, state));
};
