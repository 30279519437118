import {Editor, Path, Transforms} from "../../../../../../../pkg/slate.js";
import {LI_TYPE} from "../../../utils/constants.js";
import {getNode, isLastChild, matchElement} from "../../../utils/queries/index.js";
import {moveListItemsToList} from "./moveListItemsToList.js";
import {unwrapList} from "./unwrapList.js";
import {hasListChild} from "../queries/index.js";
export const moveListItemUp = (editor, {list, listItem}) => {
  const move = () => {
    const [listNode, listPath] = list;
    const [liNode, liPath] = listItem;
    const liParent = Editor.above(editor, {
      at: listPath,
      match: matchElement(LI_TYPE)
    });
    if (!liParent) {
      const toListPath2 = Path.next(listPath);
      const condA = hasListChild(editor, liNode);
      const condB = !isLastChild(list, liPath);
      if (condA || condB) {
        Transforms.insertNodes(editor, {type: listNode.type, children: []}, {at: toListPath2});
      }
      if (condA) {
        const toListNode = getNode(editor, toListPath2);
        if (!toListNode)
          return;
        moveListItemsToList(editor, {
          fromListItem: listItem,
          toList: [toListNode, toListPath2]
        });
      }
      if (condB) {
        const toListNode = getNode(editor, toListPath2);
        if (!toListNode)
          return;
        moveListItemsToList(editor, {
          fromList: list,
          fromStartIndex: liPath[liPath.length - 1] + 1,
          toList: [toListNode, toListPath2],
          deleteFromList: false
        });
      }
      unwrapList(editor);
      return true;
    }
    const [, liParentPath] = liParent;
    const toListPath = liPath.concat([1]);
    if (!isLastChild(list, liPath)) {
      if (!hasListChild(editor, liNode)) {
        Transforms.insertNodes(editor, {
          type: listNode.type,
          children: []
        }, {at: toListPath});
      }
      const toListNode = getNode(editor, toListPath);
      if (!toListNode)
        return;
      moveListItemsToList(editor, {
        fromListItem: liParent,
        toList: [toListNode, toListPath],
        fromStartIndex: liPath[liPath.length - 1] + 1,
        deleteFromList: false
      });
    }
    const movedUpLiPath = Path.next(liParentPath);
    Transforms.moveNodes(editor, {
      at: liPath,
      to: movedUpLiPath
    });
    return true;
  };
  let moved = false;
  Editor.withoutNormalizing(editor, () => {
    moved = move();
  });
  return moved;
};
