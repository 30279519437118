import {VIDEO_TYPE} from "../utils/constants.js";
export const withVideos = (editor) => {
  const {isVoid, isInline} = editor;
  editor.isInline = (el) => {
    return el.type === VIDEO_TYPE ? true : isInline(el);
  };
  editor.isVoid = (el) => {
    return el.type === VIDEO_TYPE ? true : isVoid(el);
  };
  return editor;
};
