import {css} from "../../../../../pkg/@emotion/css.js";
import React from "../../../../../pkg/react.js";
import {
  DefaultElement,
  useSelected,
  useFocused,
  useSlate
} from "../../../../../pkg/slate-react.js";
import {displayTextForMention, urlForMention} from "../modules/mentions/index.js";
import {dispatchUrlRequest} from "./dispatchUrlRequest.js";
import {witfulUrls} from "../../../utils/src/index.js";
export const Image = ({
  attributes,
  children,
  element
}) => {
  const selected = useSelected();
  const focused = useFocused();
  return /* @__PURE__ */ React.createElement("div", {
    ...attributes,
    style: {display: "inline-block"}
  }, /* @__PURE__ */ React.createElement("a", {
    href: element.src ? witfulUrls.imageViewer(element.src) : void 0,
    onClick: (event) => {
      dispatchUrlRequest(event);
      document.activeElement?.blur();
    },
    tabIndex: -1
  }, /* @__PURE__ */ React.createElement("img", {
    src: element.src,
    alt: element.alt,
    style: {
      width: element.width,
      maxWidth: "100%",
      height: element.height,
      boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none"
    }
  })), children);
};
export const Video = ({
  attributes,
  children,
  element
}) => {
  const selected = useSelected();
  const focused = useFocused();
  return /* @__PURE__ */ React.createElement("div", {
    ...attributes,
    style: {display: "inline-block"}
  }, /* @__PURE__ */ React.createElement("a", {
    href: element.src,
    target: "_blank",
    tabIndex: -1
  }, /* @__PURE__ */ React.createElement("video", {
    muted: true,
    autoPlay: true,
    loop: true,
    style: {
      width: "100%",
      boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none"
    }
  }, /* @__PURE__ */ React.createElement("source", {
    src: element.src,
    type: element.mimeType
  }))), children);
};
const baseChipStyling = (palette) => {
  const selected = useSelected();
  const focused = useFocused();
  return css`
    cursor: pointer;
    padding: 0px 4px 2px;
    margin: 2px;
    border-radius: 4px;
    box-shadow: ${selected && focused ? "0 0 0 3px " + (palette?.gray70 || "purple") : "none"};
    white-space: nowrap;
  `;
};
export const Mention = ({
  palette,
  attributes,
  children,
  element
}) => {
  const editor = useSlate();
  const mentionChipStyling = css`
    text-decoration: none;
    font-size: 14px;
    color: ${palette?.blue70};
    background-color: ${palette?.blue0 || "purple"};
    &:hover {
      color: ${palette?.blue80};
      background-color: ${palette?.blue10 || "purple"};
    }
  `;
  if (element.data.mentionType === "event-mention" && element.data.currentTopic) {
    const isComplete = element.data.completedAt !== null;
    const baseActionItemChipStyling = css`
      font-size: 13px;
      opacity: ${isComplete ? "0.5" : "1"};
      text-decoration: none;
    `;
    const standardPriorityStyling = css`
      color: ${isComplete ? palette?.gray60 : palette?.blue70};
      border-color: ${isComplete ? palette?.gray60 : palette?.blue70};
      border-width: 1px;
      border-style: solid;
    `;
    const mCheckIcon = isComplete ? /* @__PURE__ */ React.createElement("i", {
      className: "fal fa-check",
      style: {paddingRight: "2px", position: "relative", top: "1px"}
    }) : null;
    return /* @__PURE__ */ React.createElement("a", {
      ...attributes,
      className: [
        baseChipStyling(palette),
        baseActionItemChipStyling,
        standardPriorityStyling
      ].join(" "),
      href: witfulUrls.threads(),
      onClick: dispatchUrlRequest
    }, /* @__PURE__ */ React.createElement("span", null, mCheckIcon, /* @__PURE__ */ React.createElement("span", null, "topic")), children);
  }
  return /* @__PURE__ */ React.createElement("a", {
    ...attributes,
    className: [baseChipStyling(palette), mentionChipStyling].join(" "),
    href: urlForMention(editor, element),
    onClick: dispatchUrlRequest
  }, /* @__PURE__ */ React.createElement("span", null, displayTextForMention(element.data)), children);
};
const displayTextForActionItem = ({
  aiType
}) => {
  switch (aiType) {
    case "todo":
      return "to-do";
    case "talking-point":
      return "topic";
    default:
      console.error(`Unknown action item type: ${aiType}`);
      return "Unknown";
  }
};
export const ActionItemChip = ({
  palette,
  attributes,
  children,
  element
}) => {
  const isComplete = element.data.completedAt !== null;
  const baseActionItemChipStyling = css`
    font-size: 13px;
    opacity: ${isComplete ? "0.5" : "1"};
    text-decoration: none;
  `;
  const standardPriorityStyling = css`
    color: ${isComplete ? palette?.gray60 : palette?.blue70};
    border-color: ${isComplete ? palette?.gray60 : palette?.blue70};
    border-width: 1px;
    border-style: solid;
  `;
  const higherPriorityStyling = css`
    color: ${palette?.gray10};
    background-color: ${isComplete ? palette?.yellowMedium : palette?.yellowMedium};
  `;
  const backgroundColorStyling = element.data.aiType === "todo" && element.data.priority === "priority-high" ? higherPriorityStyling : standardPriorityStyling;
  const mCheckIcon = isComplete ? /* @__PURE__ */ React.createElement("i", {
    className: "fal fa-check",
    style: {paddingRight: "2px", position: "relative", top: "1px"}
  }) : null;
  return /* @__PURE__ */ React.createElement("a", {
    ...attributes,
    className: [
      baseChipStyling(palette),
      baseActionItemChipStyling,
      backgroundColorStyling
    ].join(" "),
    href: witfulUrls.threads(),
    onClick: dispatchUrlRequest
  }, /* @__PURE__ */ React.createElement("span", null, mCheckIcon, /* @__PURE__ */ React.createElement("span", null, displayTextForActionItem(element.data))), children);
};
export const Link = ({
  children,
  attributes,
  element
}) => /* @__PURE__ */ React.createElement("a", {
  ...attributes,
  style: {cursor: "pointer", color: "rgba(97, 100, 199)"},
  href: element.href || "",
  onClick: dispatchUrlRequest
}, children);
export const Element = ({attributes, children, element}, palette) => {
  switch (element.type) {
    case "h1":
      return /* @__PURE__ */ React.createElement("h1", {
        ...attributes
      }, children);
    case "h2":
      return /* @__PURE__ */ React.createElement("h2", {
        ...attributes
      }, children);
    case "h3":
      return /* @__PURE__ */ React.createElement("h3", {
        ...attributes
      }, children);
    case "h4":
      return /* @__PURE__ */ React.createElement("h4", {
        ...attributes
      }, children);
    case "h5":
      return /* @__PURE__ */ React.createElement("h5", {
        ...attributes
      }, children);
    case "h6":
      return /* @__PURE__ */ React.createElement("h6", {
        ...attributes
      }, children);
    case "blockquote":
      return /* @__PURE__ */ React.createElement("blockquote", {
        ...attributes
      }, children);
    case "ul":
      return /* @__PURE__ */ React.createElement("ul", {
        ...attributes
      }, children);
    case "ol":
      return /* @__PURE__ */ React.createElement("ol", {
        ...attributes
      }, children);
    case "li":
      return /* @__PURE__ */ React.createElement("li", {
        ...attributes
      }, children);
    case "lic":
      return /* @__PURE__ */ React.createElement("div", {
        ...attributes
      }, children);
    case "img":
      return /* @__PURE__ */ React.createElement(Image, {
        attributes,
        element
      }, children);
    case "video":
      return /* @__PURE__ */ React.createElement(Video, {
        attributes,
        element
      }, children);
    case "a":
      return /* @__PURE__ */ React.createElement(Link, {
        attributes,
        element
      }, children);
    case "pre":
      return /* @__PURE__ */ React.createElement("pre", {
        ...attributes
      }, children);
    case "p":
      return /* @__PURE__ */ React.createElement("div", {
        ...attributes
      }, children);
    case "mention":
      return /* @__PURE__ */ React.createElement(Mention, {
        palette,
        attributes,
        element
      }, children);
    case "action-item-chip":
      return /* @__PURE__ */ React.createElement(ActionItemChip, {
        palette,
        attributes,
        element
      }, children);
    default:
      return /* @__PURE__ */ React.createElement(DefaultElement, {
        attributes,
        element
      }, children);
  }
};
