import {nanoid} from "../../../../../../pkg/nanoid.js";
export const generateActionItemId = () => nanoid(10);
export const toActionItemElement = (aiType) => {
  const baseValues = {
    id: generateActionItemId(),
    createdAt: Date.now(),
    completedAt: null
  };
  switch (aiType) {
    case "todo": {
      return {
        type: "action-item-chip",
        children: [{text: ""}],
        data: {aiType, priority: "priority-normal", ...baseValues}
      };
    }
    case "talking-point":
      return {
        type: "action-item-chip",
        children: [{text: ""}],
        data: {aiType, ...baseValues}
      };
  }
};
export const toEventMentionElement = ({
  masterId,
  eventId,
  title,
  currentTopic = false
}) => ({
  type: "mention",
  children: [{text: ""}],
  data: {
    mentionType: "event-mention",
    id: generateActionItemId(),
    createdAt: Date.now(),
    completedAt: null,
    eventId,
    masterId,
    title,
    completedIn: null,
    currentTopic
  }
});
