export const reverse = (str) => {
  const len = str.length;
  const arr = new Array(len);
  let i = 0;
  while (i < len) {
    const word = str.charCodeAt(i);
    if (55296 <= word && word <= 56319) {
      arr[len - i] = str[i + 1];
      i++;
      arr[len - i] = str[i - 1];
      i++;
    } else {
      arr[len - i] = str[i];
      i++;
    }
  }
  return arr.join("");
};
export const indexes = (sub, str) => {
  const subLen = sub.length;
  if (subLen < 1)
    return [];
  let i = 0;
  const is = [];
  while ((i = str.indexOf(sub, i)) > -1) {
    is.push(i);
    i = i + subLen;
  }
  return is;
};
export const leftOfBack = (pattern, str) => {
  const patternIndexes = indexes(pattern, str);
  const rightMostPatternIndex = patternIndexes[patternIndexes.length - 1];
  if (!rightMostPatternIndex)
    return "";
  return str.slice(0, rightMostPatternIndex);
};
