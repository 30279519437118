import {isFirstChild} from "../../utils/queries/index.js";
import {moveListItemUp, moveListItemDown} from "./transforms/index.js";
import {getListItemEntry, isAcrossListItems} from "./queries/index.js";
export const listOnKeyDown = (editor) => (event) => {
  let moved = false;
  if (event.key === "Tab") {
    const res = getListItemEntry(editor, {});
    if (!res)
      return;
    if (isAcrossListItems(editor))
      return;
    const {list, listItem} = res;
    const [, listItemPath] = listItem;
    event.preventDefault();
    if (event.shiftKey) {
      moved = moveListItemUp(editor, {list, listItem});
      if (moved)
        event.preventDefault();
    } else {
      if (!isFirstChild(listItemPath)) {
        moveListItemDown(editor, {list, listItem});
      }
    }
  }
};
