import {Scrubber} from "../../../../../pkg/slate.js";
const textRandomizer = (fieldNames) => (key, value) => {
  if (fieldNames.includes(key)) {
    if (typeof value === "string") {
      return value.split("").map(generateRandomCharacter).join("");
    } else {
      return "... scrubbed ...";
    }
  }
  return value;
};
const generateRandomCharacter = () => {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLKMNOPQRSTUVWXYZ1234567890";
  return chars.charAt(Math.floor(Math.random() * chars.length));
};
export const init = () => Scrubber.setScrubber(textRandomizer(["text", "src", "href", "name", "email"]));
