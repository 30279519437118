import {Transforms, Editor} from "../../../../../../../pkg/slate.js";
import {toActionItemElement} from "../util.js";
import {getNodes, matchElement} from "../../../utils/queries/index.js";
export const addOrRemoveActionItem = (editor, blockNodeEntry, actionItemType) => {
  const [blockNode, blockPath] = blockNodeEntry;
  const getFirstActionItem = () => Array.from(getNodes(editor, {
    at: blockPath,
    match: (node) => matchElement("action-item-chip")(node) && node.data.aiType === actionItemType
  }))[0];
  let actionItem = getFirstActionItem();
  if (actionItem) {
    Editor.withoutNormalizing(editor, () => {
      while (actionItem) {
        Transforms.removeNodes(editor, {at: actionItem[1]});
        actionItem = getFirstActionItem();
      }
    });
    return;
  }
  const newActionItem = toActionItemElement(actionItemType);
  Transforms.insertNodes(editor, newActionItem, {
    at: blockPath.concat([blockNode.children.length])
  });
};
