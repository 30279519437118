import {eventBaseId} from "./eventBaseId.js";
export const home = () => buildUrl(`/`);
export const threads = () => buildUrl("/threads", []);
export const note = (noteId) => buildUrl(`/notes/${noteId}`);
export const person = (personId) => buildUrl(`/people/${personId}`);
export const createEventNote = (eventId, mMasterId = null, mRef = null) => buildUrl(`/notes/create/${eventId}`, [
  {name: "masterId", val: mMasterId},
  {name: "ref", val: mRef}
]);
export const seriesCollation = (masterId) => buildUrl(`/collate/series/${eventBaseId(masterId)}`);
export const nextInSeries = (masterId) => buildUrl("/next", [{name: "masterId", val: masterId}]);
export const nextOneOnOne = (personId) => buildUrl("/next", [
  {name: "personId", val: personId || "null"},
  {name: "meetingType", val: "oneOnOne"}
]);
export const nextMeeting = (personId) => buildUrl("/next", [{name: "personId", val: personId || null}]);
export const imageViewer = (imageUrl) => buildUrl("/view-image", [{name: "url", val: imageUrl}]);
const buildUrl = (path, queryParams = []) => {
  const url = new URL(`${window.location.origin}${path}`);
  for (const param of queryParams) {
    if (param.val === null)
      continue;
    url.searchParams.set(param.name, param.val);
  }
  return url.toString();
};
