import {Editor} from "../../../../../../pkg/slate.js";
export const getBlockAbove = (editor, at = editor.selection) => {
  if (!at)
    return;
  try {
    return Editor.above(editor, {
      at,
      match: (n) => Editor.isBlock(editor, n)
    });
  } catch (err) {
    return;
  }
};
