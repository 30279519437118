import {Editor} from "../../../../../../pkg/slate.js";
import {matchElement} from "../../utils/queries/index.js";
import {replaceNode} from "../../utils/transforms/index.js";
import {displayTextForMention} from "./utils.js";
export const updateStaleMentions = (editor) => (mentionNames) => {
  if (!mentionNames)
    return;
  const mentions = Array.from(Editor.nodes(editor, {
    at: [],
    mode: "all",
    match: matchElement("mention")
  }));
  mentions.forEach(([mentionNode, mentionPath]) => {
    if (mentionNode.data.mentionType === "person-mention") {
      const {name, personId} = mentionNode.data;
      const mNewName = mentionNames.people[personId];
      if (mNewName && mNewName !== name) {
        const newData = {...mentionNode.data, name: mNewName};
        const newNode = {
          ...mentionNode,
          data: newData,
          children: [{text: displayTextForMention(newData)}]
        };
        replaceNode(editor, newNode, mentionPath);
      }
    }
  });
};
