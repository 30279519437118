import {Transforms, Editor, Node, Range} from "../../../../../../../pkg/slate.js";
import {unwrapList} from "./unwrapList.js";
import {
  findNode,
  getNodes,
  isCollapsed,
  isRangeAcrossBlocks,
  matchElement
} from "../../../utils/queries/index.js";
import {ELEMENT_DEFAULT, LI_TYPE, LIC_TYPE} from "../../../utils/constants.js";
import {wrapNodes} from "../../../utils/transforms/index.js";
import {getListItemEntry, isList} from "../queries/index.js";
export const toggleList = (editor, {type}) => Editor.withoutNormalizing(editor, () => {
  if (!editor.selection) {
    return;
  }
  if (isCollapsed(editor.selection) || !isRangeAcrossBlocks(editor)) {
    const res = getListItemEntry(editor);
    if (res) {
      const {list} = res;
      if (list[0].type !== type) {
        Transforms.setNodes(editor, {type}, {
          at: editor.selection,
          match: (n) => isList(n),
          mode: "lowest"
        });
      } else {
        unwrapList(editor);
      }
    } else {
      const list = {type, children: []};
      wrapNodes(editor, list);
      const nodes = [
        ...getNodes(editor, {
          match: matchElement(ELEMENT_DEFAULT)
        })
      ];
      Transforms.setNodes(editor, {type: LIC_TYPE});
      const listItem = {
        type: LI_TYPE,
        children: []
      };
      for (const [, path] of nodes) {
        wrapNodes(editor, listItem, {
          at: path
        });
      }
    }
  } else {
    const [startPoint, endPoint] = Range.edges(editor.selection);
    const commonEntry = Node.common(editor, startPoint.path, endPoint.path);
    if (isList(commonEntry[0]) || commonEntry[0].type === LI_TYPE) {
      if (commonEntry[0].type !== type) {
        const startList = findNode(editor, {
          at: Range.start(editor.selection),
          match: isList,
          mode: "lowest"
        });
        const endList = findNode(editor, {
          at: Range.end(editor.selection),
          match: isList,
          mode: "lowest"
        });
        const rangeLength = Math.min(startList[1].length, endList[1].length);
        Transforms.setNodes(editor, {type}, {
          at: editor.selection,
          match: (n, path) => isList(n) && path.length >= rangeLength,
          mode: "all"
        });
      } else {
        unwrapList(editor);
      }
    } else {
      const rootPathLength = commonEntry[1].length;
      const nodes = Array.from(getNodes(editor, {
        mode: "all"
      })).filter(([, path]) => path.length === rootPathLength + 1).reverse();
      nodes.forEach((n) => {
        if (isList(n[0])) {
          Transforms.setNodes(editor, {type}, {at: n[1]});
        } else {
          Transforms.setNodes(editor, {type: LIC_TYPE}, {at: n[1]});
          const listItem = {
            type: LI_TYPE,
            children: []
          };
          wrapNodes(editor, listItem, {
            at: n[1]
          });
          const list = {type, children: []};
          wrapNodes(editor, list, {at: n[1]});
        }
      });
    }
  }
});
