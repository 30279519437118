import {Editor, Element} from "../../../../../../pkg/slate.js";
import {matchActionItemElements} from "./matchActionItemElements.js";
import {nanoid} from "../../../../../../pkg/nanoid.js";
export const dedupeActionItemIds = (editor) => (node) => {
  if (matchActionItemElements(node)) {
    const [nodeWithMatchingId] = Editor.nodes(editor, {
      at: [],
      match: (n) => {
        return matchActionItemElements(n) && n.data.id === node.data.id;
      }
    });
    if (nodeWithMatchingId) {
      return {
        ...node,
        children: node.children.map(dedupeActionItemIds(editor)),
        data: {...node.data, id: nanoid(10)}
      };
    }
  }
  if (Element.isElement(node)) {
    return {
      ...node,
      children: node.children.map(dedupeActionItemIds(editor))
    };
  }
  return node;
};
