import {Editor, Range} from "../../../../../../../pkg/slate.js";
import {LI_TYPE} from "../../../utils/constants.js";
import {
  getNode,
  getParent,
  isCollapsed,
  matchElement
} from "../../../utils/queries/index.js";
export const getListItemEntry = (editor, {at = editor.selection} = {}) => {
  const liType = LI_TYPE;
  let _at;
  if (Range.isRange(at) && !isCollapsed(at)) {
    _at = at.focus.path;
  } else if (Range.isRange(at)) {
    _at = at.anchor.path;
  } else {
    _at = at;
  }
  if (_at) {
    const node = getNode(editor, _at);
    if (node) {
      const listItem = Editor.above(editor, {
        at: _at,
        match: matchElement(liType)
      });
      if (listItem) {
        const list = getParent(editor, listItem[1]);
        return {list, listItem};
      }
    }
  }
  return;
};
