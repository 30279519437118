import {Editor, Transforms} from "../../../../../../pkg/slate.js";
import {isFirstChild, isSelectionAtBlockStart} from "../../utils/queries/index.js";
import {removeFirstListItem, removeListItem, unwrapList} from "./transforms/index.js";
import {getListItemEntry, isListNested} from "./queries/index.js";
import {resetNode} from "../../utils/transforms/index.js";
import {LI_TYPE} from "../../utils/constants.js";
export const deleteBackwardList = (editor, unit) => {
  const res = getListItemEntry(editor, {});
  let moved = false;
  if (res) {
    const {list, listItem} = res;
    if (isSelectionAtBlockStart(editor)) {
      Editor.withoutNormalizing(editor, () => {
        moved = removeFirstListItem(editor, {list, listItem});
        if (moved)
          return;
        moved = removeListItem(editor, {list, listItem});
        if (moved)
          return;
        if (isFirstChild(listItem[1]) && !isListNested(editor, list[1])) {
          moved = resetNode(editor, {
            type: LI_TYPE,
            predicate: () => isSelectionAtBlockStart(editor),
            onReset: (editor_) => unwrapList(editor_)
          });
          if (moved)
            return;
        }
        Transforms.delete(editor, {unit, reverse: true});
        moved = true;
        return;
      });
    }
  }
  return moved;
};
