import {Element, Transforms, Editor, Path} from "../../../../../../../pkg/slate.js";
import {normalizeListItem} from "./normalizeListItem.js";
import {
  ELEMENT_DEFAULT,
  LI_TYPE,
  LIC_TYPE,
  LIST_TYPES
} from "../../../utils/constants.js";
import {isList} from "../queries/index.js";
import {getNode, getPreviousPath, matchElement} from "../../../utils/queries/index.js";
import {moveListItemsToList} from "../transforms/index.js";
import {normalizeNestedList} from "./normalizeNestedList.js";
export const normalizeList = (editor) => {
  const {normalizeNode} = editor;
  return ([node, path]) => {
    if (!Element.isElement(node))
      return;
    if (isList(node)) {
      if (!node.children.length || !node.children.find((item) => item.type === LI_TYPE)) {
        return Transforms.removeNodes(editor, {at: path});
      }
      const nextPath = Path.next(path);
      const nextNode = getNode(editor, nextPath);
      if (nextNode?.type === node.type) {
        moveListItemsToList(editor, {
          fromList: [nextNode, nextPath],
          toList: [node, path],
          deleteFromList: true
        });
      }
      const prevPath = getPreviousPath(path);
      const prevNode = getNode(editor, prevPath);
      if (prevNode?.type === node.type) {
        editor.normalizeNode([prevNode, prevPath]);
        return;
      }
      if (normalizeNestedList(editor, {nestedListItem: [node, path]})) {
        return;
      }
    }
    if (node.type === LI_TYPE) {
      if (normalizeListItem(editor, {listItem: [node, path]})) {
        return;
      }
    }
    if (node.type === LIC_TYPE) {
      const [parentElement] = Editor.parent(editor, path);
      if (Editor.isEditor(parentElement) || Element.isElement(parentElement) && parentElement.type !== LI_TYPE) {
        Transforms.setNodes(editor, {type: ELEMENT_DEFAULT}, {at: path});
        return;
      }
    }
    if (node.type !== LI_TYPE && Editor.isBlock(editor, node)) {
      const [parentElement] = Editor.parent(editor, path);
      if (matchElement(LIST_TYPES)(parentElement)) {
        const li = {
          type: LI_TYPE,
          children: [{type: LIC_TYPE, children: node.children}]
        };
        Transforms.setNodes(editor, li, {at: path});
      }
    }
    normalizeNode([node, path]);
  };
};
