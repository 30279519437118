import React, {useCallback, useMemo, useState} from "../../../../../pkg/react.js";
import {Transforms, Editor as SlateEditor} from "../../../../../pkg/slate.js";
import {Slate, Editable, ReactEditor} from "../../../../../pkg/slate-react.js";
import lodash from "../../../../../pkg/lodash.js";
import {
  Debugger,
  Element,
  Leaf,
  HoveringToolbar,
  FixedToolbar
} from "../components/index.js";
import {onKeyDown, onKeyPress, onKeyUp} from "../keyboard/index.js";
import {Editor} from "../index.js";
import {getBlockAbove} from "../utils/queries/index.js";
import {
  MentionSelect,
  initMentions,
  updateStaleMentions
} from "../modules/mentions/index.js";
import {getActionItemById} from "../modules/actionItem/index.js";
export const Component = (props) => {
  const mentions = initMentions(props.plugins.mentions);
  const editor = useMemo(() => Editor.init(props.plugins), []);
  const [palette, setPalette] = useState(props.palette);
  const [slateDoc, setSlateDoc] = useState(props.slateDoc);
  const onChange = useCallback((newSlateDoc) => {
    if (lodash.isEqual(newSlateDoc, slateDoc))
      return;
    setSlateDoc(newSlateDoc);
    mentions.onChange(editor);
    props.onChange(editor);
  }, [slateDoc, editor]);
  const scrollToActionItem = useCallback((actionItemId) => {
    if (actionItemId) {
      const actionItem = getActionItemById(editor, actionItemId);
      if (!actionItem)
        return;
      const actionItemBlock = getBlockAbove(editor, actionItem[1]);
      if (!actionItemBlock)
        return;
      const [aiBlock, aiBlockPath] = actionItemBlock;
      const domNode = ReactEditor.toDOMNode(editor, aiBlock);
      const endOfBlockPoint = SlateEditor.end(editor, aiBlockPath);
      if (!endOfBlockPoint)
        return;
      ReactEditor.focus(editor);
      Transforms.select(editor, endOfBlockPoint);
      domNode.classList.add("slate-highlight-action-item");
      setTimeout(() => domNode.classList.remove("slate-highlight-action-item"), 2e3);
    }
  }, []);
  const remotelySetSlateDoc = (newSlateDoc) => {
    if (lodash.isEqual(newSlateDoc, editor.children))
      return;
    editor.children = newSlateDoc;
    setSlateDoc(newSlateDoc);
  };
  props.onInit({
    setSlateDoc: remotelySetSlateDoc,
    scrollToActionItem,
    setPalette,
    syncMentionNames: updateStaleMentions(editor)
  });
  return /* @__PURE__ */ React.createElement(Slate, {
    editor,
    value: slateDoc,
    onChange
  }, /* @__PURE__ */ React.createElement(FixedToolbar, null), /* @__PURE__ */ React.createElement(Editable, {
    style: {height: "100%", overflow: "auto"},
    className: props.readOnly ? "slate-readonly" : "slate-editable",
    renderLeaf: Leaf,
    renderElement: (renderElementProps) => Element(renderElementProps, palette),
    placeholder: "Leave a note",
    onKeyDown: (event) => {
      mentions.onKeyDown(editor)(event);
      onKeyDown(editor)(event);
    },
    onKeyPress: onKeyPress(editor),
    onKeyUp: (event) => {
      mentions.onKeyUp(event);
      onKeyUp(editor)(event);
    },
    readOnly: props.readOnly
  }), props.debug ? /* @__PURE__ */ React.createElement(Debugger, null) : null, /* @__PURE__ */ React.createElement(HoveringToolbar, null), /* @__PURE__ */ React.createElement(MentionSelect, {
    ...mentions.getMentionSelectProps(palette)
  }));
};
