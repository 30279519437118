import {LI_TYPE} from "../../../utils/constants.js";
import {
  isCollapsed,
  isRangeAcrossBlocks,
  matchElement,
  someNode
} from "../../../utils/queries/index.js";
export const isAcrossListItems = (editor) => {
  const {selection} = editor;
  if (!selection || isCollapsed(selection)) {
    return false;
  }
  const isAcrossBlocks = isRangeAcrossBlocks(editor);
  if (!isAcrossBlocks)
    return false;
  return someNode(editor, {
    match: matchElement(LI_TYPE)
  });
};
